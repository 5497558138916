import { NavLink } from 'react-router-dom';
import PrimaryButton from './PrimaryButton';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Tooltip from '@material-ui/core/Tooltip';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { globalStyles } from '../style/gotheme';
import PageRow from './PageRow';
import PlayIcon from '@material-ui/icons/PlayCircleFilled';
import CloseIcon from '@material-ui/icons/Close';
import LinkIcon from '@material-ui/icons/Link';
import DescriptionIcon from '@material-ui/icons/Description';

const styles = theme => {
  return Object.assign({
    getLink: {
      textAlign: 'right',
      '&:hover': {
        cursor: 'pointer'
      }
    },
    videoThumbnail: {
      width: 340,
      height: 200,
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
      '&:hover': {
        cursor: 'pointer'
      }
    },
    playVideo: {
      marginTop: -120,
      marginBottom: 80,
      textAlign: 'center',
      '&:hover': {
        cursor: 'pointer'
      }
    },
    videoCard: {
      width: 340,
      maxWidth: '100%',
      marginRight: 20,
      marginBottom: 20,
      borderRadius: 4,
      boxShadow: '0px 6px 12px #00000029',
      backgroundColor: 'white'
    },
    wpCard: {
      width: 340,
      maxWidth: '100%',
      padding: 12,
      margin: 12,
      borderRadius: 4,
      boxShadow: '0px 6px 12px #00000029',
      backgroundColor: 'white'
    }
  }, globalStyles)
};

const videos = [
  {
    name: "Inviting users to your Graphologi subscription",
    desc: "How to invite users to collaborate with you on your data.",
    image: "inviting-users.png",
    link: "https://www.youtube.com/embed/NNFd1KAKlzA"
  },
  {
    name: "Create a taxonomy project",
    desc: "Creating a taxonomy project is the first step to managing your taxonomies.",
    image: "create-taxonomy-project.png",
    link: "https://www.youtube.com/embed/5XOFN9DHVNo"
  },
  {
    name: "Add languages to a taxonomy project",
    desc: "You can add additional languages to your project after it has been created.",
    image: "add-project-language.png",
    link: "https://www.youtube.com/embed/yNFfSGR6kWE"
  },
  {
    name: "Set up project snapshots",
    desc: "Snapshots allows you to make backups of your projects.",
    image: "setup-snapshots.png",
    link: "https://www.youtube.com/embed/2BAuLsgMJbY"
  },
  {
    name: "Using ontologies with taxonomies",
    desc: "Add additional relationships and properties to your concepts and labels.",
    image: "associate-ontologies.png",
    link: "https://www.youtube.com/embed/SzzOeBkUxIw"
  },
  {
    name: "Working with polyhierarchy or monohierarchy",
    desc: "The editing mode for a taxonomy has various options allowing you to control how you want your data structured.",
    image: "editing-mode.png",
    link: "https://www.youtube.com/embed/sq_F0qXA-Jc"
  },
  {
    name: "Add classes to taxonomy concepts",
    desc: "Using classes allows you to explicitly define what type of thing a concept is.",
    image: "add-class.png",
    link: "https://www.youtube.com/embed/tRD4Q_RNUtI"
  },
  {
    name: "Creating a new top concept suggestion",
    desc: "Suggestions allow users to contribute ideas to a taxonomy.",
    image: "new-top-concept-suggestion.png",
    link: "https://www.youtube.com/embed/4xSQ7asHRkA"
  },
  {
    name: "Creating connections outside of a project",
    desc: "Create connections to other taxonomy projects and to linked data sources.",
    image: "links.png",
    link: "https://www.youtube.com/embed/HbipYjRak8w"
  },
  {
    name: "Import a taxonomy from a spreadsheet",
    desc: "Import spreadsheets, TSV or CSV files.",
    image: "import-spreadsheet.png",
    link: "https://www.youtube.com/embed/A6BOlQxL968"
  },
  {
    name: "Advanced spreadsheet import",
    desc: "Import spreadsheets using relationships, ontology properties and classes.",
    image: "advanced-spreadsheet-import.png",
    link: "https://www.youtube.com/embed/u8T3WqhpZDo"
  },
  {
    name: "Create a TSV output for a taxonomy",
    desc: "TSV output can be used in spreadsheet applications.",
    image: "create-tsv.png",
    link: "https://www.youtube.com/embed/RuAXiY45NPc"
  },
  {
    name: "Reporting",
    desc: "Run predefined reports or create custom reports.",
    image: "reporting.png",
    link: "https://www.youtube.com/embed/X6jctv_KJug"
  }
]

class Learn extends Component {

  constructor(props) {
    super(props);
    const isLinked = props.location.hash.substring(1);
    const selected = isLinked ?
      videos.find(video => video.link === isLinked)
      :
      null;
    this.state = {
      selected
    };
  }

  select = (video) => {
    this.props.history.push("/learn#" + video.link);
    this.setState({ selected: Object.assign({}, video) })
  };

  unselect = () => {
    this.props.history.push("/learn");
    this.setState({ selected: null })
  }

  render() {

    const { classes, width, tutorials } = this.props;
    const { selected } = this.state;
    const isLarge = width === "md" || width === "lg" || width === "xl";

    return (
      <div>
        {isLarge &&
          <div style={{ position: 'absolute', overflow: 'hidden', width: '100%', right: 0 }}>
            <img
              src="/images/GraphologiIcon.svg"
              style={{
                float: 'right',
                height: 764,
                marginTop: '-100px',
                marginRight: -160
              }}
              alt="" />
          </div>
        }
        <PageRow spaceTop={isLarge ? "100px" : "36px"}>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h1">Learn</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" style={{ marginTop: 18 }} paragraph>Find out more about taxonomies, ontologies and knowledge graphs,<br /> plus how to get the best from Graphologi.</Typography>
            </Grid>
          </Grid>
        </PageRow>
        <PageRow spaceTop={isLarge ? "70px" : "20px"} color="#F2F7FF">
          <Typography style={{ marginBottom: 40 }} paragraph align="center" variant="h1">Resources</Typography>
          <div style={{ display: isLarge ? 'flex' : 'block', flexWrap: 'wrap', justifyContent: 'center' }}>
            {tutorials.map((tutorial, index) =>
              <div key={index} className={classes.wpCard}>
                <div style={{ paddingBottom: 40 }}>
                  <Typography variant="caption"><DescriptionIcon fontSize="large" style={{ verticalAlign: 'middle' }} /> Tutorial</Typography>
                  <div style={{ marginTop: 20 }}>
                    <Typography variant="h2">{tutorial.title}</Typography>
                  </div>
                </div>
                <div style={{ clear: 'both' }}>
                  <div style={{ height: tutorial.longTitle ? 120 : 180 }}>
                    <Typography paragraph variant="body1">{tutorial.description}</Typography>
                  </div>
                  <div style={{ textAlign: 'right' }}>
                    <NavLink to={"/" + tutorial.url + "/"}>
                      <PrimaryButton onClick={() => { }}>Read</PrimaryButton>
                    </NavLink>
                  </div>
                </div>
              </div>
            )}
          </div>
        </PageRow>
        <PageRow spaceTop={isLarge ? "70px" : "20px"} color="#F2F7FF">
          <Typography style={{ marginBottom: 40 }} paragraph align="center" variant="h1">Video Tutorials</Typography>
          <div style={{ display: isLarge ? 'flex' : 'block', flexWrap: 'wrap', justifyContent: 'center' }}>
            {videos.map((video, index) =>
              <div key={index} className={classes.videoCard}>
                {isLarge ?
                  <div>
                    <img
                      className={classes.videoThumbnail}
                      onClick={() => this.select(video)}
                      src={"/images/" + video.image}
                      alt={video.name} />
                    <div className={classes.playVideo}>
                      <PlayIcon
                        onClick={() => this.select(video)}
                        color="secondary"
                        style={{ height: 40, width: 40 }} />
                    </div>
                  </div> :
                  <div style={{ padding: 4, borderTopLeftRadius: 4, borderTopRightRadius: 4 }}>
                    <iframe
                      style={{ width: '100%' }}
                      src={video.link}
                      title={video.name}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen></iframe>
                  </div>
                }
                <div style={{ padding: 8 }}>
                  <Typography paragraph variant="body2" style={{ height: '2.4em' }}>{video.name}</Typography>
                  <Typography variant="body1">{video.desc}</Typography>
                  <div className={classes.getLink}>
                    <CopyToClipboard text={"https://graphologi.com/learn#" + video.link}>
                      <Tooltip title="Copy link to video">
                        <LinkIcon />
                      </Tooltip>
                    </CopyToClipboard>
                  </div>
                </div>
              </div>
            )}
          </div>
        </PageRow>
        {selected &&
          <div>
            <div className={classes.playBackground} />
            <div className={isLarge ? classes.closeContainer : classes.narrowCloseContainer}>
              <Tooltip title="Close video player">
                <CloseIcon className={classes.close} onClick={this.unselect} />
              </Tooltip>
            </div>
            <div className={isLarge ? classes.viewer : classes.narrowViewer}>
              <div style={{ height: 'calc(100% - 100px)' }}>
                <iframe
                  style={{ height: '100%', width: '100%' }}
                  src={selected.link}
                  title={selected.name}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen></iframe>
              </div>
              <div style={{ padding: 12 }}>
                <Typography paragraph variant="body2">{selected.name}</Typography>
                {isLarge &&
                  <Typography variant="body1">{selected.desc}</Typography>
                }
              </div>
            </div>
          </div>
        }
      </div>
    );
  }
}

Learn.propTypes = {
}

export default withRouter(withWidth()(withStyles(styles)(Learn)));