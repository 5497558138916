import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import { globalStyles as styles } from '../style/gotheme';

class GettingStartedWithStandards extends Component {

  render() {

    const { classes } = this.props;

    return (
      <div>
        <Typography paragraph variant="h2" className={classes.wpH2}>Why use Standards?</Typography>
        <Typography paragraph variant="body1">Many companies still manage taxonomies in spreadsheets or using in-house systems. Working in this way has potential problems such as the maintenance and interoperability of any data. This can be especially so when there is a team of taxonomists or stakeholders involved in the creation and maintenance of taxonomies. Different users may have different ideas on how data should be managed or governed. It can also be difficult to work collaboratively due to the nature of spreadsheets or the limits of any in-house system.
        </Typography>
        <Typography paragraph variant="body1">In terms of interoperability without the data being defined in a standard way there will almost always be the need for additional work to move that data between different systems, adding time, cost and complexity to managing that data.
        </Typography>
        <Typography paragraph variant="h2" className={classes.wpH2}>SKOS: <em>The</em> Standard for Taxonomies</Typography>
        <Typography paragraph variant="body1">These days most taxonomy systems are built around the <a className={classes.wpLink} href="https://www.w3.org/TR/skos-reference/" target="_new">SKOS</a> standard. This defines a model for organising knowledge structures such as taxonomies and thesauri. By using the SKOS standard it makes any data created in those systems portable and interoperable and the rules for working with that data are clearly defined.</Typography>
        <Typography paragraph variant="body1">It is, however, important that a standard does not get in the way of users and it is the job of any application implementing the standard to ensure that users are not burdened with additional effort in managing their data. In Graphologi we have taken great care to ensure that any complexity involved with working with SKOS is removed from the user and handled by the system.</Typography>
        <Typography paragraph variant="body1">In this document we go over some of the fundamentals of SKOS and how you work with it in Graphologi. We will base this around a tourism theme. Example projects for this theme are available for trial users of Graphologi to make it easy to load data and get started working with it in Graphologi.</Typography>
        <img alt="Example project" className={classes.wpImage} src="/images/getting-started-0.png" />
        <Typography paragraph variant="body1">The details of SKOS are described in more detail in the SKOS primer document at:</Typography>
        <Typography paragraph variant="body1" align="center"><a className={classes.wpLink} href="https://www.w3.org/TR/skos-primer/" target="_new">https://www.w3.org/TR/skos-primer/</a></Typography>
        <Typography paragraph variant="h2" className={classes.wpH2}>Concepts</Typography>
        <Typography paragraph variant="body1">The most fundamental thing in SKOS is the idea of a ‘concept’. A concept, as mentioned in the primer, is a ‘unit of thought’ - an idea.</Typography>
        <Typography paragraph variant="body1">We start with an example concept, in this case the idea of a ‘cruise holiday’. A cruise is a type of holiday and in SKOS we can group together concepts in what are called ‘concept schemes’. Within a concept scheme concepts can be organised as a flat list or in a hierarchy with as many levels as required. In Graphologi this looks like the following image.</Typography>
        <img alt="Concepts view" className={classes.wpImage} style={{ width: 300 }} src="/images/getting-started-1.png" />
        <Typography paragraph variant="body1">We can see concepts such as ‘Beach’, ‘Cruise’ and ‘Cycling’. These concepts are known as ‘top concepts’. They are still concepts, but they are the most general concepts in our taxonomy.</Typography>
        <Typography paragraph variant="h2" className={classes.wpH2}>A Hierarchy of Concepts</Typography>
        <Typography paragraph variant="body1">Top concepts can have more specific concepts associated with them. In the image above we have ‘River cruise’ and ‘Transatlantic cruise’ as examples of these. In SKOS these are <em>narrower</em> concepts of ‘Cruise’. SKOS also defines the inverse of this, so ‘Cruise’ is a <em>broader</em> concept of ‘River Cruise’. This is shown in the image below.</Typography>
        <img alt="Narrower and broader relation direction" className={classes.wpImage} style={{ width: 600 }} src="/images/getting-started-2.png" />
        <Typography paragraph variant="body1">Unfortunately the way that SKOS defines these does tend to cause confusion as to which way round the relationships work. The easiest way to understand them is to add ‘has’ in front of the relationship when thinking about them - so ‘Cruise has narrower River cruise’.</Typography>
        <Typography paragraph variant="h2" className={classes.wpH2}>Labels</Typography>
        <Typography paragraph variant="body1">Concepts are just ideas but we need a way to describe them using language. In SKOS this is done using labels. In each concept scheme every concept must have what is called a ‘preferred label’. There can only be one preferred label in any language. You can, though, have as many languages as you need for each concept. In Graphologi our preferred label for our ‘Cruise’ concept looks like the following:</Typography>
        <img alt="Preferred labels" className={classes.wpImage} style={{ width: 400 }} src="/images/getting-started-3.png" />
        <Typography paragraph variant="body1">We can see the ‘en’ tag to indicate that this language is in English. The ‘en’ tag is a language tag as defined by another standard - <a href="https://en.wikipedia.org/wiki/IETF_language_tag" target="_new" className={classes.wpLink}>BCP 47</a>. However, for the purposes of using languages in Graphologi you just need to select which languages you want to work with in your project. There is a tutorial video showing you how to do this: <a href="https://graphologi.com/learn#https://www.youtube.com/embed/yNFfSGR6kWE" className={classes.wpLink}>Add languages to a taxonomy project</a>
        </Typography>
        <Typography paragraph variant="body1">You quickly get used to recognising which tag a language belongs to, but in Graphologi, if you forget, you can just hover over the tag and it will remind you, as in the following example image:</Typography>
        <img alt="Language tag display" className={classes.wpImage} style={{ width: 160 }} src="/images/getting-started-4.png" />
        <Typography paragraph variant="body1">In real life ideas are generally referred to in many different ways. Therefore, SKOS also defines the idea of ‘alternative labels’ - synonyms. Unlike preferred labels there can be as many alternative labels as necessary.</Typography>
        <Typography paragraph variant="body1">We can see how this looks in Graphologi, where, for Newark airport in the USA, it has had various names over the years and so we have captured these as alternative labels for the concept.</Typography>
        <img alt="Alternative labels" className={classes.wpImage} style={{ width: 400 }} src="/images/getting-started-5.png" />
        <Typography paragraph variant="h2" className={classes.wpH2}>Connecting Concepts
        </Typography>
        <Typography paragraph variant="body1">It is quite possible that concepts in our concept scheme are connected to each other, but not through narrower or broader relationships. SKOS defines a ‘related’ relationship which allows us to connect these concepts together.</Typography>
        <Typography paragraph variant="body1">In our tourism example we have two concepts for types of holiday - ‘Ski’ and ‘Sports and activity’. Depending upon how you like to spend your holiday the strength of any relationship will vary of course, but for our example taxonomy we have stated that the two concepts are related. The image below shows how this looks in Graphologi.</Typography>
        <img alt="Conncecting concepts" className={classes.wpImage} style={{ width: 700 }} src="/images/getting-started-6.png" />
        <Typography paragraph variant="body1">SKOS defines yet more relationships that can be used to create relationships between concepts in <em>different</em> concept schemes. These are the ‘match’ properties - ‘exact match’, ‘close match’, ‘related match’, ‘narrow match’ and ‘broad match’. The SKOS primer gives more details on when each is appropriate to use:</Typography>
        <Typography paragraph variant="body1" align="center"><a className={classes.wpLink} href="https://www.w3.org/TR/skos-primer/#secmapping" target="_new">https://www.w3.org/TR/skos-primer/#secmapping</a></Typography>
        <Typography paragraph variant="h2" className={classes.wpH2}>Concept Management
        </Typography>
        <Typography paragraph variant="body1">When managing taxonomies it may be important to record additional information about concepts or the governance of those concepts. SKOS defines a set of different types of ‘notes’ that can be used for this. There are ‘definitions’, ‘examples’, ‘editorial notes’, ‘change notes’, ‘history notes’ and ‘scope notes’.</Typography>
        <Typography paragraph variant="body1">Definition and scope notes are generally used to give more information about the meaning and context for the use of a concept. Example notes give you a chance to list examples for the use of a concept. History notes can be used to record changes to the meaning of a concept over time. Editorial notes and change notes are used for recording information about editorial processes and how details of a concept have changed from an administrative perspective.</Typography>
        <Typography paragraph variant="body1">With the note properties it is important to assess the overhead of using and managing notes and whether there are good reasons to use them. For example, Graphologi records a detailed audit trail of changes to concepts so potentially provides some of the necessary detail that may be required without the burden of manually recording it.</Typography>
        <Typography paragraph variant="body1">More detail on the note properties can be found in the SKOS primer at:
        </Typography>
        <Typography paragraph variant="body1" align="center"><a className={classes.wpLink} href="https://www.w3.org/TR/skos-primer/#secdocumentation" target="_new">https://www.w3.org/TR/skos-primer/#secdocumentation</a>
        </Typography>
        <Typography paragraph variant="h2" className={classes.wpH2}>Summary</Typography>
        <Typography paragraph variant="body1">This is just a brief overview of using the SKOS standard and how to work with it in Graphologi. By working in this way you open up a world of opportunities to more easily manage and use your taxonomy data within and beyond your organisation.</Typography>
      </div>
    );
  }
}

export default withStyles(styles)(GettingStartedWithStandards);