import { Typography } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { globalStyles, gotheme } from '../style/gotheme';

const styles = theme => {
  return Object.assign({
    featureText: {
      fontFamily: 'Roboto',
      fontWeight: 400
    },
    featureListItem: {
      paddingTop: 0,
      paddingBottom: 0
    },
    featureListText: {
      fontFamily: 'Roboto',
      fontWeight: 400,
      lineHeight: '26px',
      paddingTop: 3
    },
    listItemAvatar: {
      marginTop: '0px',
    }
  }, globalStyles)
};

class FeatureList extends Component {

  render() {

    const { classes, items, image, title, description } = this.props;

    return (
      <div style={{margin: '0px 5px 0px 5px', textAlign: 'center', height: '100%'}}>
        <div style={{padding: 12, border: 'solid 1px #e0e0e0', borderRadius: 24, marginTop: 20, marginBottom: 20, height: 'calc(100% - 40px)'}}>
        <img style={{ marginTop: 12, height: 110, marginBottom: 18 }} src={"/images/" + image} alt="" />
        <Typography variant="h2" align="center">{title}</Typography>
        <div style={{ minHeight: 50 }}>
          <Typography className={classes.featureText}>{description}</Typography>
        </div>
        <List>
          {items.map((item, index) =>
            <ListItem
              classes={{
                alignItemsFlexStart: classes.listItemAvatar
              }}
              key={index}
              alignItems="flex-start"
              disableGutters
              className={classes.featureListItem}>
              <ListItemAvatar>
                <CheckCircleIcon style={{ color: gotheme.palette.secondary.main }} />
              </ListItemAvatar>
              <ListItemText disableTypography className={classes.featureListText} primary={item} />
            </ListItem>
          )}
        </List>
        </div>
      </div>
    );
  }
}

FeatureList.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired
}

export default withStyles(styles)(FeatureList);