export const gotheme = {
  props: {
    MuiButtonBase: {
      disableRipple: true
    },
  },
  overrides: {
    MuiGrid: {
      "spacing-xs-2": {
        margin: '-8px',
        width: '100%'
      }
    },
    MuiListItemAvatar: {
      root: {
        minWidth: '28px'
      }
    },
    MuiTooltip: {
      tooltip: {
        fontSize: "12px",
        color: "white",
        zIndex: 10000,
        backgroundColor: "#01579B"
      }
    },
    MuiButton: {
      containedSecondary: {
        '&.Mui-disabled': {
          backgroundColor: '#eeeeee',
          boxShadow: '0px 1px 3px #00000029'
        }
      }
    },
    MuiIconButton: {
      root: {
        borderRadius: '4px',
        color: '#616161'
      }
    },
    MuiOutlinedInput: {
      notchedOutline: {
        borderColor: '#9e9e9e'
      }
    }
  },
  typography: {
    useNextVariants: true,
    bodyFontSize: "14px",
    fontFamily: "rajdhani",
    h1: {
      fontSize: "48px",
      lineHeight: "54px",
      fontWeight: 500,
      color: "#13294B"
    },
    h2: {
      fontSize: "34px",
      lineHeight: "44px",
      fontWeight: 500,
      color: "#13294B"
    },
    body1: {
      fontSize: "16px",
      fontFamily: "Roboto",
      lineHeight: "26px",
      fontWeight: 400,
      color: "#13294B"
    },
    body2: {
      fontSize: "24px",
      lineHeight: "34px",
      fontWeight: 500,
      color: "#13294B"
    },
    caption: {
      fontSize: "16px",
      lineHeight: "26px",
      textAlign: "center",
      fontWeight: 400,
      fontFamily: "Roboto",
      color: "#13294B"
    }
  },
  "palette": {
    "common": {
      "black": "#000",
      "white": "#fff"
    },
    "background": {
      "paper": "#fff",
      "default": "#f5f5f5",
      "medium": "#eeeeee"
    },
    "border": {
      "main": "solid 1px #9e9e9e"
    },
    "primary": {
      "light": "#01579B",
      "main": "#13294B",
      "dark": "#0080B0",
      "contrastText": "#ffffff"
    },
    "secondary": {
      "light": "#00ff00",
      "main": "#97d700",
      "dark": "#84BC01",
      "contrastText": "#13294B"
    },
    "default": {
      "light": "#bbb",
      "main": "#aaa",
      "dark": "#888",
      "contrastText": "#000"
    },
    "error": {
      "light": "#00ff00",
      "main": "#DD2C00",
      "dark": "#00ff00",
      "contrastText": "#fff"
    },
    "text": {
      "primary": "#212121",
      "secondary": "rgba(0, 0, 0, 0.54)",
      "disabled": "#616161",
      "hint": "rgba(0, 0, 0, 0.38)",
    }
  }
};

export const globalStyles = {
  logo: {
    height: 50
  },
  gridLeft: {
    paddingRight: 12
  },
  gridRight: {
    paddingLeft: 12
  },
  mainSubHeading: {
    fontSize: "18px",
    fontFamily: "Roboto",
    lineHeight: "28px",
    marginTop: 12,
    fontWeight: 400,
    color: "#13294B"
  },
  cancelButton: {
    fontSize: '16px',
    whiteSpace: 'nowrap',
    textOverflow: 'hidden',
    fontWeight: 500,
    fontFamily: 'Roboto',
    padding: '6px 16px'
  },
  button: {
    boxShadow: 'none',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '16px',
    whiteSpace: 'nowrap',
    textOverflow: 'hidden',
    fontFamily: 'Roboto',
    padding: '16px 24px',
    letterSpacing: '0.06em',
    borderRadius: '8px',
    '&:hover': {
      boxShadow: '0px 3px 6px #00000029'
    }
  },
  fixedWidthButton: {
    fontWeight: 700,
    fontSize: '16px',
    width: 256,
    height: '50px',
    letterSpacing: '0.06em',
    whiteSpace: 'nowrap',
    textOverflow: 'hidden',
    fontFamily: 'Roboto',
    borderRadius: '8px',
    '&:hover': {
      boxShadow: '0px 3px 6px #00000029'
    }
  },
  words: {
    fontSize: "20px",
    lineHeight: "34px",
    marginRight: 20,
    fontStyle: "italic"
  },
  standardLargeText: {
    fontSize: "24px",
    lineHeight: "34px",
    fontWeight: 500,
    fontFamily: 'rajdhani',
    color: "#13294B",
    marginBottom: 0
  },
  standardSoftText: {
    fontSize: "14px",
    lineHeight: "26px",
    fontWeight: 400,
    marginTop: 26,
    fontFamily: "Roboto",
    color: "#616161"
  },
  linkText: {
    fontSize: "16px",
    textTransform: 'uppercase',
    lineHeight: "26px",
    fontWeight: 500,
    marginTop: 26,
    fontFamily: "Roboto",
    color: "#13294B"
  },
  menuItem: {
    display: 'inline-block',
    textTransform: 'uppercase',
    marginRight: 20,
    fontSize: "16px",
    lineHeight: "19px",
    fontWeight: 500,
    fontFamily: "Roboto",
    color: "#ffffff"
  },
  menuItemSelected: {
    display: 'inline-block',
    textTransform: 'uppercase',
    marginRight: 20,
    color: '#97d700',
    fontSize: "16px",
    lineHeight: "19px",
    fontWeight: 500,
    fontFamily: "Roboto"
  },
  menuItemLarge: {
    display: 'inline-block',
    textTransform: 'uppercase',
    marginRight: 0,
    fontSize: "20px",
    lineHeight: "24px",
    fontWeight: 500,
    fontFamily: "Roboto",
    color: "#ffffff"
  },
  menuItemBlock: {
    display: 'block',
    textTransform: 'uppercase',
    fontSize: "16px",
    lineHeight: "19px",
    fontWeight: 500,
    marginBottom: 26,
    fontFamily: "Roboto",
    color: "#ffffff"
  },
  accordion: {
    backgroundColor: 'inherit',
    boxShadow: 'none',
    marginLeft: 0
  },
  policyText: {
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: 400,
    marginBottom: 12,
    color: "#13294B",
    fontFamily: "Roboto",
  },
  policyHeading: {
    fontSize: "24px",
    lineHeight: "34px",
    marginTop: 24,
    fontWeight: 500,
    color: "#13294B",
    fontFamily: 'rajdhani'
  },
  closeContainer: {
    position: 'fixed',
    top: 60,
    right: 'calc((100% - 870px)/2 - 30px)',
    zIndex: 5
  },
  narrowCloseContainer: {
    position: 'fixed',
    top: 60,
    right: 10,
    zIndex: 5
  },
  close: {
    color: 'white',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  playBackground: {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: '#13294B',
    opacity: 0.7,
    zIndex: 5
  },
  viewer: {
    position: 'fixed',
    top: 80,
    left: 'calc((100% - 870px)/2)',
    right: 'calc((100% - 870px)/2)',
    bottom: 80,
    padding: 5,
    backgroundColor: 'white',
    borderRadius: 4,
    zIndex: 5
  },
  narrowViewer: {
    position: 'fixed',
    top: 80,
    left: 40,
    right: 40,
    bottom: 80,
    padding: 5,
    backgroundColor: 'white',
    borderRadius: 4,
    zIndex: 5
  },
  wpH2: {
    marginTop: 40,
    fontSize: 28
  },
  wpLink: {
    color: 'blue',
    '&:visited': {
      color: 'blue'
    }
  },
  wpDocLink: {
    marginLeft: 72,
    marginRight: 72,
    border: 'solid 1px #e5e5e5',
    padding: 12,
    borderRadius: 4,
    marginBottom: 24
  },
  wpImage: {
    display: 'block',
    padding: 24,
    maxWidth: 'calc(100% - 48px)',
    backgroundColor: 'white',
    borderRadius: 4,
    margin: '24px auto'
  },
  wpList: {
    listStyle: 'square',
    color: '#97d700'
  },
  wpCode: {
    fontSize: "15px",
    border: 'solid 1px #e5e5e5',
    backgroundColor: '#f8f8f8',
    padding: 12,
    borderRadius: 4,
    marginBottom: 24
  },
  wpInlineCode: {
    fontFamily: 'monospace',
    display: 'inline',
    fontSize: "14px",
    border: 'solid 1px #e5e5e5',
    backgroundColor: '#f8f8f8',
    padding: '2px 6px',
    borderRadius: 4,
    marginBottom: 24
  },
  wpTable: {
    marginBottom: 20,
    border: 'solid 1px #e5e5e5',
    backgroundColor: '#ffffff',
    borderRadius: '4px'
  },
  wpTableText: {
    fontSize: '16px',
    lineHeight: '22px',
    fontWeight: 400,
    fontFamily: 'Roboto',
    verticalAlign: 'top'
  },
  wpTableHeadingRow: {
    backgroundColor: '#00A9E0'
  },
  wpTableHeading: {
    fontSize: "18px",
    lineHeight: "20px",
    fontWeight: 500,
    fontFamily: 'rajdhani',
    color: "white"
  }

};
