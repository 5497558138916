import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import { globalStyles } from '../style/gotheme';
import withWidth from '@material-ui/core/withWidth';
import Grid from '@material-ui/core/Grid';
import PageRow from './PageRow';

const styles = theme => {
  return Object.assign({
  }, globalStyles)
};

class Privacy extends Component {

  render() {

    const { classes, width } = this.props;
    const isLarge = width === "md" || width === "lg" || width === "xl";

    return (
      <div>
        {isLarge &&
          <div style={{ position: 'absolute', overflow: 'hidden', width: '100%', right: 0 }}>
            <img
              src="/images/GraphologiIcon.svg"
              style={{
                float: 'right',
                height: 764,
                marginTop: '-100px',
                marginRight: -160
              }}
              alt="" />
          </div>
        }
        <PageRow spaceTop={isLarge ? "100px" : "36px"} spaceBottom={isLarge ? "50px" : "0px"} color={isLarge ? null : "#F2F7FF"}>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h1" style={{ marginBottom: isLarge ? "100px" : "0px" }}>Privacy Policy</Typography>
            </Grid>
          </Grid>
        </PageRow>
        <PageRow spaceTop={isLarge ? "70px" : "0px"} color="#F2F7FF">
          <div style={{ maxWidth: '624px', margin: '0 auto' }}>
            <Typography className={classes.policyText}>We ask that you read this website privacy policy carefully as it contains important information on who we are, how and why we collect, store, use and share personal information, your rights in relation to your personal information and on how to contact us and supervisory authorities in the event you have a complaint.</Typography>
            <Typography className={classes.policyHeading}>Who we are</Typography>
            <Typography className={classes.policyText}>This website is operated by Graphifi Ltd. We collect, use and are responsible for certain personal information about you. When we do so we are regulated under the General Data Protection Regulation which applies across the European Union (including in the United Kingdom) and we are responsible as ‘controller’ of that personal information for the purposes of those laws.</Typography>
            <Typography className={classes.policyHeading}>Our website</Typography>
            <Typography className={classes.policyText}>This privacy policy relates to your use of our website graphologi.com.</Typography>
            <Typography className={classes.policyHeading}>Our collection and use of your personal information</Typography>
            <Typography className={classes.policyText}>We collect personal information about you when you contact us via the website.</Typography>
            <Typography className={classes.policyText}>We collect this personal information from you either directly, such as when you register your interest for our products or contact us directly.</Typography>
            <Typography className={classes.policyText}>We retain this personal information while you have an account with us and for a period of time afterwards depending on the type of the information. We take steps to minimise how much information we retain.</Typography>
            <Typography className={classes.policyHeading}>Our legal basis for processing your personal information</Typography>
            <Typography className={classes.policyText}>When we use your personal information we are required to have a legal basis for doing so. There are various different legal bases on which we may rely, depending on what personal information we process and why.</Typography>
            <Typography className={classes.policyText}>The legal bases we may rely on include:</Typography>
            <ul>
              <li><Typography className={classes.policyText}><strong>consent:</strong> where you have given us clear consent for us to process your personal information for a specific purpose</Typography></li>
              <li><Typography className={classes.policyText}><strong>contract:</strong> where our use of your personal information is necessary for a contract we have with you, or because you have asked us to take specific steps before entering into a contract</Typography></li>
              <li><Typography className={classes.policyText}><strong>legal obligation:</strong> where our use of your personal information is necessary for us to comply with the law (not including contractual obligations)</Typography></li>
              <li><Typography className={classes.policyText}><strong>legitimate interests:</strong> where our use of your personal information is necessary for our legitimate interests or the legitimate interests of a third party (unless there is a good reason to protect your personal information which overrides our legitimate interests)</Typography></li>
            </ul>
            <Typography className={classes.policyHeading}>Transfer of your information out of the EEA</Typography>
            <Typography className={classes.policyText}>We do not transfer your information out of the EEA. We will let you know if that changes by updating this policy.</Typography>
            <Typography className={classes.policyHeading}>Cookies and other tracking technologies</Typography>
            <Typography className={classes.policyText}>A cookie is a small text file which is placed onto your device (eg computer, smartphone or other electronic device) when you use our website. We do not use cookies on our website.</Typography>
            <Typography className={classes.policyHeading}>Marketing</Typography>
            <Typography className={classes.policyText}>We would like to send you information about products and updates, which may be of interest to you. Where we have your consent or it is in our legitimate interests to do so, we may do this by post, email, telephone, text message (SMS) or automated call.</Typography>
            <Typography className={classes.policyText}>We will only send you marketing messages when you tick the relevant boxes when you register your interest. If you have previously agreed to being contacted in this way, you can unsubscribe at any time by:</Typography>
            <ul>
              <li>
                <Typography className={classes.policyText}>contacting us; or</Typography>
              </li>
              <li>
                <Typography className={classes.policyText}>using the ‘unsubscribe’ link in emails or ‘STOP’ number in texts</Typography>
              </li>
            </ul>
            <Typography className={classes.policyHeading}>Your rights</Typography>
            <Typography className={classes.policyText}>Under the General Data Protection Regulation you have a number of important rights free of charge. In summary, those include rights to:</Typography>
            <ul>
              <li><Typography className={classes.policyText}>access to your personal information and to certain other supplementary information that this Privacy Notice is already designed to address</Typography></li>
              <li><Typography className={classes.policyText}>require us to correct any mistakes in your information which we hold</Typography></li>
              <li><Typography className={classes.policyText}>require the erasure of personal information concerning you in certain situations</Typography></li>
              <li><Typography className={classes.policyText}>receive the personal information concerning you which you have provided to us, in a structured, commonly used and machine-readable format and have the right to transmit those data to a third party in certain situations</Typography></li>
              <li><Typography className={classes.policyText}>object at any time to processing of personal information concerning you for direct marketing</Typography></li>
              <li><Typography className={classes.policyText}>object to decisions being taken by automated means which produce legal effects concerning you or similarly significantly affect you</Typography></li>
              <li><Typography className={classes.policyText}>object in certain other situations to our continued processing of your personal information</Typography></li>
              <li><Typography className={classes.policyText}>otherwise restrict our processing of your personal information in certain circumstances</Typography></li>
              <li><Typography className={classes.policyText}>claim compensation for damages caused by our breach of any data protection laws</Typography></li>
            </ul>
            <Typography className={classes.policyText}>For further information on each of those rights, including the circumstances in which they apply, see the Guidance from the UK Information Commissioner’s Office (ICO) on individuals rights under the General Data Protection Regulation.</Typography>
            <Typography className={classes.policyText}>If you would like to exercise any of those rights, please email our Data Protection Officer, including enough information to identify you and let us know the information relating to your request.</Typography>
            <Typography className={classes.policyHeading}>Keeping your personal information secure</Typography>
            <Typography className={classes.policyText}>We have appropriate security measures in place to prevent personal information from being accidentally lost, or used or accessed in an unauthorised way. We limit access to your personal information to those who have a genuine business need to know it.</Typography>
            <Typography className={classes.policyHeading}>How to complain</Typography>
            <Typography className={classes.policyText}>We hope that our Data Protection Officer can resolve any query or concern you raise about our use of your information.</Typography>
            <Typography className={classes.policyText}>The General Data Protection Regulation also gives you the right to lodge a complaint with a supervisory authority, in particular in the European Union (or European Economic Area) state where you work, normally live or where any alleged infringement of data protection laws occurred. The supervisory authority in the UK is the Information Commissioner who may be contacted at <a href="https://ico.org.uk/concerns/">https://ico.org.uk/concerns/</a> or telephone: 0303 123 1113.</Typography>
            <Typography className={classes.policyHeading}>How to contact us</Typography>
            <Typography className={classes.policyText}>Please contact our Data Protection Officer by emailing <a href="mailto:paulappleby@graphifi.com">paulappleby@graphifi.com</a> if you have any questions about this privacy notice or the information we hold about you.</Typography>
            <Typography className={classes.policyHeading}>Do you need extra help?</Typography>
            <Typography className={classes.policyText}>If you would like this website privacy policy in another format (for example: audio, large print, braille) please contact us (see ‘How to contact us’ above).</Typography>
          </div>
        </PageRow>
      </div>
    );
  }
}

export default withWidth()(withStyles(styles)(Privacy));