import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import withWidth from '@material-ui/core/withWidth';
import CloseIcon from '@material-ui/icons/Close';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import MenuIcon from '@material-ui/icons/Menu';
import TwitterIcon from '@material-ui/icons/Twitter';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { globalStyles, gotheme } from '../style/gotheme';
import PageRow from './PageRow';
import PrimaryButton from './PrimaryButton';

const styles = theme => {
  return Object.assign({
    barMiddle: {
      flexGrow: 1
    },
    login: {
      display: 'inline-block',
      textTransform: 'uppercase',
      fontSize: "16px",
      lineHeight: "19px",
      fontWeight: 500,
      fontFamily: "Roboto",
      color: "#ffffff",
      marginLeft: 24,
      whiteSpace: 'nowrap',
      border: 'solid 1px white',
      letterSpacing: '0.06em',
      borderRadius: 8,
      '&:hover': {
        background: 'rgba(27, 87, 178, 1)',
        boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.16)'
      },
      padding: '8px 12px'
    }
  }, globalStyles)
};

class StandardAppBar extends Component {

  goToGraphologi = () => {
    window.location.href = "https://graphologi.graphifi.com/gs/signup";
  }

  goToGraphologiLogin = () => {
    window.location.href = "https://graphologi.graphifi.com";
  }

  render() {

    const { width, classes, onHideMenu, onShowMenu, showMenu, blend } = this.props
    const path = this.props.location.pathname;

    return (
      <AppBar position="static" style={blend ?
        { backgroundColor: 'transparent', boxShadow: 'none' }
        :
        {}}>
        {showMenu ?
          <div id="top" style={{
            backgroundColor: gotheme.palette.primary.main,
            textAlign: 'center',
            position: 'absolute',
            top: 0,
            bottom: 0,
            right: 0,
            left: 0
          }}>
            <div>
              <Toolbar>
                <NavLink to="/" style={{ marginTop: 4, paddingLeft: 20 }}>
                  <img className={classes.logo} src="/images/logo-reversed-crop.png" alt="Logo" />
                </NavLink>
                <span className={classes.barMiddle} />
                <IconButton edge="start" color="inherit" aria-label="menu" onClick={onHideMenu}>
                  <CloseIcon />
                </IconButton>
              </Toolbar>
              <div onClick={() => onHideMenu()} style={{ marginBottom: 50 }}>
                <NavLink to="/pricing" className={classes.menuItemLarge}>Pricing</NavLink>
              </div>
              <div onClick={() => onHideMenu()} style={{ marginBottom: 50 }}>
                <NavLink to="/learn" className={classes.menuItemLarge}>Learn</NavLink>
              </div>
              <div onClick={() => onHideMenu()} style={{ marginBottom: 50 }}>
                <a href="/docs/" className={classes.menuItemLarge}>Docs</a>
              </div>
              <div onClick={() => onHideMenu()} style={{ marginBottom: 50 }}>
                <NavLink to="/contact" className={classes.menuItemLarge}>Contact</NavLink>
              </div>
              <div style={{ marginBottom: 50 }}>
                <PrimaryButton
                  compact={true}
                  onClick={this.goToGraphologi}>Try Graphologi</PrimaryButton>
              </div>
              <div onClick={() => onHideMenu()}>
                <NavLink to="/privacy" className={classes.menuItemBlock}>Privacy Policy</NavLink>
              </div>
              <div>
                <span>
                  <a href="https://twitter.com/graphifi"><TwitterIcon /></a>
                </span>
                <span style={{ marginLeft: 10 }}>
                  <a href="https://linkedin.com/company/graphifi"><LinkedInIcon /></a>
                </span>
              </div>
            </div>
          </div>
          :
          <PageRow spaceBottom="0px">
            <Toolbar>
              <span style={{ marginTop: 4 }}>
                <NavLink to="/">
                  {width === "xs" || width === "sm" || width === "md" ?
                    <img className={classes.logo} src="/images/logo-reversed-crop.png" alt="Logo" />
                    :
                    <img className={classes.logo} src="/images/logo-reversed.png" alt="Logo" />
                  }
                </NavLink>
              </span>
              <span className={classes.barMiddle} />
              {width !== "xs" && width !== "sm" && width !== "md" &&
                <React.Fragment>
                  <NavLink
                    to="/pricing"
                    style={path === "/pricing" ? { color: gotheme.palette.secondary.main } : {}}
                    className={classes.menuItem}>Pricing</NavLink>
                  <NavLink
                    to="/learn"
                    style={path === "/learn" ? { color: gotheme.palette.secondary.main } : {}}
                    className={classes.menuItem}>Learn</NavLink>
                  <a
                    href="/docs"
                    style={path === "/docs/" ? { color: gotheme.palette.secondary.main } : {}}
                    className={classes.menuItem}>Docs</a>
                  <NavLink
                    to="/contact"
                    style={path === "/contact" ? { color: gotheme.palette.secondary.main } : {}}
                    className={classes.menuItem}>Contact</NavLink>
                </React.Fragment>
              }
              <PrimaryButton
                compact={true}
                onClick={this.goToGraphologi}>{(width === "xs" || width === "sm" || width === "md") ?
                  "Sign Up"
                  :
                  "Try Graphologi"
                }</PrimaryButton>
              {(width !== "xs" && width !== "sm" && width !== "md") &&
                <a href="https://graphologi.graphifi.com"
                  className={classes.login}>Log In</a>
              }
              {(width === "xs" || width === "sm" || width === "md") &&
                <IconButton edge="end" color="inherit" aria-label="menu" onClick={onShowMenu}>
                  <MenuIcon />
                </IconButton>
              }
            </Toolbar>
          </PageRow>
        }
      </AppBar>
    )
  }
}

StandardAppBar.defaultProps = {
  blend: false
}

StandardAppBar.propTypes = {
  blend: PropTypes.bool,
  onShowMenu: PropTypes.func.isRequired,
  onHideMenu: PropTypes.func.isRequired,
  showMenu: PropTypes.bool.isRequired
}

export default withWidth()(withStyles(styles)(withRouter(StandardAppBar)));
