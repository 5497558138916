import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import { globalStyles } from '../style/gotheme';

const styles = theme => {
  return Object.assign({
  }, globalStyles)
};

class Conditions extends Component {

  render() {

    const { classes } = this.props;

    return (
      <div>Conditions</div>
    );
  }
}

export default withStyles(styles)(Conditions);