import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import withWidth from '@material-ui/core/withWidth';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import TwitterIcon from '@material-ui/icons/Twitter';
import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { globalStyles, gotheme } from '../style/gotheme';
import PageRow from './PageRow';

const styles = theme => {
  return Object.assign({
  }, globalStyles)
};

class StandardFooter extends Component {

  goHome = () => this.props.history.push("/")

  render() {

    const { width, classes } = this.props
    const isSmall = width === "xs" || width === "sm";

    return (
      <div>
        <PageRow color={gotheme.palette.primary.main} spaceTop={isSmall ? "60px" : "30px"}>
          <div style={{ color: '#ffffff', height: isSmall ? "30px" : "156px" }}>
            <Grid container spacing={1}>
              <Grid item xs={8} md={4}>
                <NavLink to="/">
                  <img className={classes.logo} src="/images/logo-reversed.png" alt="Logo" />
                </NavLink>
              </Grid>
              {!isSmall &&
                <React.Fragment>
                  <Grid item xs={3}>
                    <div style={{ marginTop: 12 }}>
                      <NavLink to="/pricing" className={classes.menuItemBlock}>Pricing</NavLink>
                      <NavLink to="/learn" className={classes.menuItemBlock}>Learn</NavLink>
                      <a href="/docs" className={classes.menuItemBlock}>Docs</a>
                      <NavLink to="/contact" className={classes.menuItemBlock}>Contact</NavLink>
                    </div>
                  </Grid>
                  <Grid item xs={3}>
                    <div style={{ marginTop: 12 }}>
                      <NavLink to="/privacy" className={classes.menuItemBlock}>Privacy Policy</NavLink>
                      <NavLink to="/release" className={classes.menuItemBlock}>Release Notes</NavLink>
                      <div>
                        <span>
                          <a href="https://twitter.com/graphifi"><TwitterIcon /></a>
                        </span>
                        <span style={{ marginLeft: 10 }}>
                          <a href="https://linkedin.com/company/graphifi"><LinkedInIcon /></a>
                        </span>
                      </div>
                    </div>
                  </Grid>
                </React.Fragment>
              }
            </Grid>
          </div>
        </PageRow>
        <PageRow color={gotheme.palette.primary.main} spaceBottom="50px" spaceTop="0px">
          <div style={isSmall ? { textAlign: 'right', color: '#ffffff' } : { color: '#ffffff' }} >
            <Grid container>
              <Grid item xs={12} md={4}>
                <Typography className={classes.standardSoftText} style={{ color: '#ffffff', marginTop: 6 }}>
                  <span>Graphifi&#160;Ltd is a company registered in England and Wales</span>
                </Typography>
                <Typography className={classes.standardSoftText} style={{ color: '#ffffff', marginTop: 0 }}>
                  <span>Company&#160;No.&#160;12034454</span>
                </Typography>
                <Typography className={classes.standardSoftText} style={{ color: '#ffffff', marginTop: 0 }}>
                  <span>VAT&#160;Registration&#160;No.&#160;328&#160;1956&#160;82</span>
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Grid container>
                  <Grid item xs={8}>
                    <div style={{ color: '#ffffff', textAlign: 'right' }}>
                      <a href="https://www.aicpa.org/soc4so" target="_new">
                      <img style={{ verticalAlign: 'top', width: 100, marginRight: 20, marginBottom: 20 }} src="/images/21972-312_SOC_NonCPA.png" alt="SOC2 Type 2 logo" />
                      </a>
                      <img style={{ verticalAlign: 'top', width: 100, marginRight: 20 }} src="/images/iso27001.svg" alt="ISO 27001 logo" />
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className={classes.standardSoftText} style={{ marginTop: 0, color: '#ffffff' }}>
                      <span>Graphifi Ltd is certified according to ISO27001:2022 and SOC2 Type 2</span></Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography
                  className={classes.standardSoftText}
                  style={isSmall ? { marginTop: "32px", color: '#ffffff' } : { marginTop: 0, textAlign: 'right', color: '#ffffff' }}>
                  <a href="https://graphifi.com">
                    <img style={{ verticalAlign: 'middle', width: 180 }} src="/images/GraphifiLogoRGBReversed.png" alt="Graphifi logo" />
                  </a>
                </Typography>
                <Typography className={classes.standardSoftText} style={{ marginTop: 0, color: '#ffffff', textAlign: 'right', marginRight: 10 }}>Copyright © 2024</Typography>
                <Typography className={classes.standardSoftText} style={{ marginTop: 0, color: '#ffffff', textAlign: 'right', marginRight: 10 }}>Graphologi.com does not store<br/>any cookies on your device</Typography>
              </Grid>
            </Grid>
          </div>
        </PageRow >
      </div >
    )
  }
}

StandardFooter.defaultProps = {
}

StandardFooter.propTypes = {
}

export default withWidth()(withStyles(styles)(withRouter(StandardFooter)));
