import { Typography } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { globalStyles } from '../style/gotheme';

const styles = theme => {
  return Object.assign({
  }, globalStyles)
};

class PricingFAQ extends Component {

  render() {

    const { classes } = this.props;

    return (
      <div>
        <Typography variant="h1">Frequently Asked Questions</Typography>
        <Accordion className={classes.accordion}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="body2">How much data will I be able to store?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>As rough indicators, a Standard subscription should be able to store a few thousand concepts or labels. A Premium subcription should be able to store millions.</Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className={classes.accordion}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="body2">What does high availability mean?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>Graphologi hosts the service on AWS in Ireland (Dublin) and Germany (Frankfurt). Your data is stored in both regions to ensure service availability in the unlikely situation that AWS has an outage in one of those regions.</Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className={classes.accordion}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="body2">Where is my data stored?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>Graphologi hosts the service on AWS in Ireland (Dublin) and Germany (Frankfurt). Your data is stored in both regions. If you have data privacy requirements which mean that these locations are not suitable please get in touch and we can discuss an Ultimate subscription.</Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className={classes.accordion}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="body2">What happens to my data if I cancel my subscription?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>We would advise that you export all data before cancelling your subscription. Your data will be deleted approximately one week after the expiration date. We will store backups for 30 days so in the event you need to access your data after the subscription expires you will have this period in which to contact us. Please refer to the conditions of your subscription for details.</Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className={classes.accordion}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="body2">How much notice do I have to give to cancel my subscription?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>For monthly subscriptions it is a rolling monthly subscription - you can cancel at any time and your subscription will expire at the end of the billing month. For yearly subscriptions you can cancel at any time and your subscription will expire at the end of the 12 month billing period. You and any invited users will still be able to access your subscription up to the expiration date.</Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className={classes.accordion}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="body2">What happens if I delete my account?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>Before deleting your account you need to cancel any subscriptions. If you then proceed to delete your account any subscription will be immediately expired for security purposes and any invited users will be excluded from accessing the subscription. Note this does not apply to Ultimate subscriptions.</Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className={classes.accordion}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="body2">What is different about an Ultimate subscription?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>An Ultimate subscription is your own dedicated Graphologi instance. It also gives you complete control over the management of users. There is also the option to enable single sign-on. You can also decide where you would like your instance of Graphologi deployed (dependent upon AWS capabilities).</Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className={classes.accordion}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="body2">What options are available for single sign-on?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>Graphologi supports SAML 2.0 for single sign-on. This means that almost all systems can be integrated.</Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    );
  }
}

export default withRouter(withWidth()(withStyles(styles)(PricingFAQ)));