import { NavLink } from 'react-router-dom';
import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import { globalStyles as styles } from '../style/gotheme';

class ElementsOfKG extends Component {

  render() {

    const { classes } = this.props;

    return (
      <div>
        <Typography paragraph variant="h2" className={classes.wpH2}>The Elements of a Knowledge Graph</Typography>
        <Typography paragraph variant="body1">We hear the phrase ‘knowledge graph’ mentioned a lot these days. But what does a knowledge graph consist of and how are those things connected?</Typography>
        <img alt="Knowledge graph elements" className={classes.wpImage} src="/images/elements-kg.png" />
        <Typography paragraph variant="body1">In general there are three elements to a knowledge graph:</Typography>
        <ul className={classes.wpList}>
          <li><Typography paragraph variant="body1">Ontologies are the ‘models’ of the domain that you are describing</Typography></li>
          <li><Typography paragraph variant="body1">Taxonomies are controlled, hierarchical lists of items. Note that we use the word ‘taxonomy’ loosely. It also covers things like thesauri, etc.</Typography></li>
          <li><Typography paragraph variant="body1">Data consists of the ‘things’ that you need to store information for.</Typography></li>
        </ul>
        <Typography paragraph variant="body1">There is another useful part to a knowledge graph, called inference, that can use ontologies to compute new information. Much of this is advanced stuff but there are a few bits that you will see frequently in Graphologi because they are so useful.</Typography>
        <Typography paragraph variant="body1">For example, ‘inverse’ and ‘symmetric’ properties are very common in taxonomies. Examples of inverses are ‘narrower’ and ‘broader’ items in the taxonomy - if A is narrower B, then we can compute B is broader A. Examples of symmetric relationships are ‘related’ - if A knows B we can compute that B knows A.</Typography>
        <Typography paragraph variant="body1">Another is class hierarchies. You might define ‘Car’ as a subclass of ‘Vehicle’. Graphologi understands that if something has the ‘Car’ class it will also be a ‘Vehicle’.</Typography>
        <Typography paragraph variant="body1">In Graphologi you can use one or more ontologies. How many ontologies make sense will depend upon circumstances. For instance, there may be pre-existing ontologies that you want to use but you may also need to create your own to supplement those. You might want to break down your ontologies into different business domains. The important thing to remember is that, within Graphologi you can connect your ontologies together easily.</Typography>
        <Typography paragraph variant="body1">Ontologies can be very simple or very complex. Many use cases require nothing more than the definition of a few ‘classes’ of information and some properties. Examples might be ‘Airport’, ‘Country’ for classes and ‘Located In’ or ‘Altitude’ for properties. These are simple to create and use on taxonomies and data.</Typography>
        <Typography paragraph variant="body1">We often get asked ‘when is something a taxonomy and when is something just data’? In many situations the answer is obvious, but that isn’t always the case. Here are some things to consider:</Typography>
        <Table className={classes.wpTable}>
          <TableHead>
            <TableRow className={classes.wpTableHeadingRow}>
              <TableCell><Typography className={classes.wpTableHeading}>Taxonomy</Typography></TableCell>
              <TableCell><Typography className={classes.wpTableHeading}>Data</Typography></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell className={classes.wpTableText}>You need to organise your information in a hierarchical manner</TableCell>
              <TableCell className={classes.wpTableText}>It is a flat list of items</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.wpTableText}>It is a finite list of items</TableCell>
              <TableCell className={classes.wpTableText}>It may be a very large list of items</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.wpTableText}>The frequency of change is slow</TableCell>
              <TableCell className={classes.wpTableText}>The frequency of change is fast</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.wpTableText}>You need your data in the SKOS model for interoperability purposes</TableCell>
              <TableCell className={classes.wpTableText}>You need your data in a standard form but not necessarily in SKOS</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.wpTableText}>Items must have unique labels</TableCell>
              <TableCell className={classes.wpTableText}>Items with the same label might occur more than once (e.g. hotel names)</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.wpTableText}>You need to add context to the labels on items. See the article <NavLink to="/skos-xl-context" className={classes.wpLink}>Adding Context to Labels</NavLink> for more on this.</TableCell>
              <TableCell className={classes.wpTableText}></TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.wpTableText}>It is important to have alternative labels (synonyms) for items</TableCell>
              <TableCell className={classes.wpTableText}>Things tend to only have a single label</TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Typography paragraph variant="body1">One of the examples you can load in a Graphologi trial is based around a tourism theme. In that example it includes the following:</Typography>
        <ul className={classes.wpList}>
          <li><Typography paragraph variant="body1">Hotels (as data)</Typography></li>
          <li><Typography paragraph variant="body1">Restaurants (as data)</Typography></li>
          <li><Typography paragraph variant="body1">Countries (as a taxonomy)</Typography></li>
          <li><Typography paragraph variant="body1">Type of holiday (as a taxonomy)</Typography></li>
          <li><Typography paragraph variant="body1">Airports (as a taxonomy)</Typography></li>
          <li><Typography paragraph variant="body1">Months (as a taxonomy)</Typography></li>
        </ul>
        <Typography paragraph variant="body1">Most of the above list fall into the taxonomy or data fairly easily. Airports are a more interesting example of where it can be a little grey. Some might implement it as a taxonomy, others as data. In the example project the airports also have other alternative names that they have used in the past, which from our table above hints that a taxonomy is the best way to manage this information because the SKOS model provides this by default.</Typography>
        <Typography paragraph variant="body1">Several of the example taxonomies are flat lists, so it is not always the case that flat lists are not taxonomies. The key considerations here are often the size of the list and the frequency of change. Months never change, countries rarely.</Typography>
        <Typography paragraph variant="body1">Within Graphologi you can ‘enrich’ items in taxonomies or datasets equally well using ‘templates’ and classes from the ontologies. Classes, such as ‘Airport’, from the ontology can be applied to items to be more explicit about what they are. Templates can be used to group together properties (fields/attributes) that can be used to add information to items and to connect them together.</Typography>
        <Typography paragraph variant="body1">For items in datasets you will almost certainly use both ontologies and taxonomies because many of the properties of items in a dataset naturally fall into the definitions of taxonomies.</Typography>
        <Typography paragraph variant="body1">You can see in the example below that ‘The Savoy’ hotel has a class of ‘Hotel’ and makes use of several properties, such as ‘Has Facility’. This property takes its values from a taxonomy, as does ‘Located In’.</Typography>
        <img alt="Data Manager Savoy Hotel" className={classes.wpImage} src="/images/elements-dm-1.png" />
        <Typography paragraph variant="body1">Data becomes even more valuable when it is connected together. In the following image you can see an example of connected data items, where a restaurant ‘is part of the establishment’ that is one of the hotels from the hotels dataset.</Typography>
        <img alt="Data Manager restaurant" className={classes.wpImage} src="/images/elements-dm-2.png" />
        <Typography paragraph variant="body1">Creating data in this way means that the information is semantically rich, connected and standards compliant. Under the covers everything is connected using unique identifiers (called IRIs), ensuring absolute clarity in what things in the graph are. It also makes it very  easy to support multiple languages.</Typography>
        <Typography paragraph variant="body1">All of this semantically rich information means you can query and analyse the graph to ask questions or validate information. Knowledge graphs can support a wide and ever increasing variety of use cases.</Typography>
        <Typography paragraph variant="body1">The Graphifi team is always available to discuss any of the details in this article and how they might apply to you.</Typography>
      </div>
    );
  }
}

export default withStyles(styles)(ElementsOfKG);