import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import { globalStyles as styles } from '../style/gotheme';
import { NavLink } from 'react-router-dom';
import HelpIcon from '@material-ui/icons/Help';
import FastForwardIcon from '@material-ui/icons/FastForward';
import SkosTutorialLink from './SkosTutorialLink';

class SkosXLcontext extends Component {

  render() {

    const { classes } = this.props;

    return (
      <div>

        <Typography paragraph variant="body1">Many taxonomists have heard of <a className={classes.wpLink} href="https://www.w3.org/TR/skos-reference/" target="_new">SKOS</a> and understand its model is based around the idea of a ‘concept’. That is very different to older models of taxonomies based simply on ‘terms’.</Typography>
        <SkosTutorialLink/>
        <Typography paragraph variant="body1">A concept in SKOS can have preferred labels in multiple languages, albeit only one per language. It can also have multiple alternative labels (synonyms) and hidden labels, the latter of which are not commonly used but have use cases such as query expansion in searching.</Typography>
        <Typography paragraph variant="body1">In SKOS labels are ‘strings’ - that is, just pieces of text, optionally with a language tag. There is nothing more you can say about that piece of text. This model is more than good enough for many use cases. However, there are situations where you sometimes want to go further and say more about the labels themselves. Essentially labels need additional information or context.</Typography>
        <Typography paragraph variant="h2" className={classes.wpH2}>Introducing SKOS-XL</Typography>
        <Typography paragraph variant="body1">Fortunately there is another standard for this - <a className={classes.wpLink} href="https://www.w3.org/TR/skos-reference/#xl" target="_new">SKOS-XL</a>. It is less well known than SKOS, both in its potential application and how to actually use it. One of the additional complications of SKOS-XL is that, to get the most of it, you need to add additional properties to enrich the labels over and above what SKOS-XL defines itself (which is almost nothing). This means dipping a toe into the world of ontologies. But don’t let that scare you - a little bit of ontology can get you a very long way.</Typography>
        <Typography paragraph variant="body1">First, let’s discuss potential use cases for SKOS-XL.</Typography>
        <Typography paragraph variant="body1">It is not uncommon in large organisations to have different business units using different terms for the same concepts. This is an ideal scenario for the use of SKOS-XL, which, when used in conjunction with properties from an ontology, would allow you to say things like ‘this label should be used by business units X, Y and Z’.</Typography>
        <Typography paragraph variant="body1">Another application is geographic context. Language tags alone are not necessarily enough for localisation - you often need location too. For instance you might have a label in British English, but that only applies in England and not Scotland. SKOS-XL would allow you to say ‘only use this label in England’.</Typography>
        <Typography paragraph variant="body1">A further example is temporal context. Labels may be embargoed until a particular date, or may have become obsolete, but may still be useful to store for archival purposes. Again, SKOS-XL allows you to say things like ‘only use this label from this date’.</Typography>
        <Typography paragraph variant="body1">A final use is a name change context. For example, Newark airport in the USA has changed its name several times over the years. SKOS-XL allows you to say things like ‘label X replaced label Y’.</Typography>
        <Typography paragraph variant="h2" className={classes.wpH2}>Using SKOS-XL</Typography>
        <Typography paragraph variant="body1">Let’s look at how you actually implement the last example.</Typography>
        <Typography paragraph variant="body1">We will need to create a mini-ontology to define some properties. We’ll define just two:</Typography>
        <ul className={classes.wpList}>
          <li>
            <Typography className={classes.wpListItem} paragraph variant="body1">‘Start Date’ (to indicate that a label should only be used from a certain date)</Typography>
          </li>
          <li>
            <Typography paragraph variant="body1">‘Replaced Name’ (to indicate that a label replaced another label)</Typography>
          </li>
        </ul>
        <Typography paragraph variant="body1">We’ll use Newark airport as our example, which was renamed on the 31st October 2002. Newark Liberty International Airport was originally Newark Metropolitan Airport and later Newark International Airport, so it has changed name a few times.</Typography>
        <Typography paragraph variant="body1">We will take the following steps, building everything from scratch in Graphologi:</Typography>
        <ol className={classes.wpOrderedList}>
          <li>
            <Typography paragraph variant="body1">Create an ontology project and set up our ontology.</Typography>
          </li>
          <li>
            <Typography paragraph variant="body1">Create a taxonomy project.</Typography>
          </li>
          <li>
            <Typography paragraph variant="body1">Connect our ontology project to our taxonomy project by setting up a ‘property template’. Property templates group ontology properties together for use on taxonomies. They can get quite sophisticated but our example will be very simple.</Typography>
          </li>
          <li>
            <Typography paragraph variant="body1">Create a taxonomy and tell it to use our template.</Typography>
          </li>
          <li>
            <Typography paragraph variant="body1">Create our Newark concept and its labels.</Typography>
          </li>
          <li>
            <Typography paragraph variant="body1">Connect our labels together.</Typography>
          </li>
        </ol>
        <Typography paragraph variant="h2" className={classes.wpH2}>Setting up the Ontology</Typography>
        <Typography paragraph variant="body1">The first thing we will do is set up the ontology and create our two properties.</Typography>
        <Typography paragraph variant="body1">In Graphologi go to the Ontology Manager and click ‘Create Project’ and go through the steps to create a project.</Typography>
        <Typography paragraph variant="body1">Now click ‘Add Ontology’ and add an ontology using details similar to the image below. Note we have selected ‘Label’ for IRI format.</Typography>
        <img alt="" className={classes.wpImage} style={{ width: 400 }} src="/images/create-ontology.png" />
        <Typography paragraph variant="body1">Go into the ontology. (Note that, in our example, we have done an additional step to remove spaces from IRIs, which is controlled from the ontology details screen. But don’t worry about that for this example if you don’t want to.)</Typography>
        <Typography paragraph variant="body1">Now click on ‘Object’. Object properties are relationships. Add a property using the name as in the image below:</Typography>
        <img alt="" className={classes.wpImage} style={{ width: 400 }} src="/images/name-prop.png" />
        <Typography paragraph variant="body1">We want to state that this property is expected to only be used on SKOS-XL labels. So in the ‘Domain’ panel click add and select ‘Label’ from the list you see in the dialog, as in the screenshot below:</Typography>
        <img alt="" className={classes.wpImage} style={{ width: 400 }} src="/images/name-domain.png" />
        <Typography paragraph variant="body1">Do the same for ‘Range’. We will also check ‘Functional’ in the ‘Characteristics’ panel, which indicates that we are only expecting a single value for this property.</Typography>
        <Typography paragraph variant="body1">When that is added the screen should look like this:</Typography>
        <img alt="" className={classes.wpImage} src="/images/name-prop-info.png" />
        <Typography paragraph variant="body1">Now we need to add our ‘Start Date’ property. Click on ‘Datatype’. Datatype properties are attributes. Add a property by entering the label for it as in the screenshot below.</Typography>
        <img alt="" className={classes.wpImage} style={{ width: 400 }} src="/images/start-date.png" />
        <Typography paragraph variant="body1">We now want to add a ‘Range’ for this property, so in that panel click add and select ‘Date’ as in the screenshot below:</Typography>
        <img alt="" className={classes.wpImage} style={{ width: 400 }} src="/images/start-date-range.png" />
        <Typography paragraph variant="body1">Once that is added our ‘Start Date’ property should look something like the following:</Typography>
        <img alt="" className={classes.wpImage} src="/images/start-date-complete.png" />
        <Typography paragraph variant="body1">And that’s it for the ontology side of things. For many scenarios ontologies don’t need to get any more complex than this.</Typography>
        <Typography paragraph variant="h2" className={classes.wpH2}>Creating a Taxonomy Project</Typography>
        <Typography paragraph variant="body1">Now we need to create a taxonomy project. In the ‘Vocabulary Manager’ create a project similar to the screenshots below:</Typography>
        <img alt="" className={classes.wpImage} style={{ width: 400 }} src="/images/tourism-graph-project.png" />
        <Typography paragraph variant="body1">When you get to the SKOS-XL step be sure to check this option:</Typography>
        <img alt="" className={classes.wpImage} style={{ width: 400 }} src="/images/enable-skosxl.png" />
        <Typography paragraph variant="body1">Finish the steps and your project will be ready.</Typography>
        <Typography paragraph variant="h2" className={classes.wpH2}>Connecting the Taxonomy and Ontology Projects</Typography>
        <Typography paragraph variant="body1">The next step is to connect our ontology with our taxonomy project. To do this we will create a ‘property template’. In the taxonomy project, on the left of the screen, click on the ‘Associated Ontology Projects’ icon (shown expanded in the image below).</Typography>
        <img alt="" className={classes.wpImage} style={{ width: 300 }} src="/images/aop-link.png" />
        <Typography paragraph variant="body1">Click ‘Add Project’ and add your ontology project from the list you see. The screen should look something like this:</Typography>
        <img alt="" className={classes.wpImage} src="/images/aop.png" />
        <Typography paragraph variant="body1">We now add our template. In the ‘Templates’ panel click the add icon and create a template, naming it something sensible like ‘Airport Names’. After doing that the screen should look similar to the following:</Typography>
        <img alt="" className={classes.wpImage} src="/images/airport-template.png" />
        <Typography paragraph variant="body1">We need to allow this template on SKOS-XL labels so check that option.</Typography>
        <Typography paragraph variant="body1">Now we need to add our two properties so in ‘Properties to Display’ click the add icon and you should see a dialog similar to the one below. Add both properties.</Typography>
        <img alt="" className={classes.wpImage} style={{ width: 400 }} src="/images/add-property-to-template.png" />
        <Typography paragraph variant="body1">Once that is done the screen should look like the following image:</Typography>
        <img alt="" className={classes.wpImage} src="/images/template-with-props.png" />
        <Typography paragraph variant="body1">We now have our template set up and ready to use.</Typography>
        <Typography paragraph variant="h2" className={classes.wpH2}>Creating our Taxonomy</Typography>
        <Typography paragraph variant="body1">We can now create our taxonomy. Go back to the project homepage and click ‘Add Taxonomy’. Fill in the detail in a similar way to the screenshot below:</Typography>
        <img alt="" className={classes.wpImage} style={{ width: 400 }} src="/images/airport-taxonomy.png" />
        <Typography paragraph variant="body1">After clicking ‘Add’ the taxonomy should be created. Click on the name to go into the taxonomy.
          We now need to tell this taxonomy that we want it to use our template that we set up earlier. Go into the taxonomy details screen (by clicking on the green icon next to the taxonomy name) and scroll down to ‘Property Templates’. Click the add icon. The screen for this should look similar to the image below. Add the template.</Typography>
        <img alt="" className={classes.wpImage} src="/images/add-template-to-taxonomy.png" />
        <Typography paragraph variant="body1">You can now toggle the taxonomy details screen to hide it again.</Typography>
        <Typography paragraph variant="h2" className={classes.wpH2}>Creating our Newark Concept</Typography>
        <Typography paragraph variant="body1">We can now create our Newark concept. Click on ‘Add’ to add a concept to the taxonomy. You should see a dialog similar to the following. Enter the label and make sure you select ‘Create as XL Label’. Add the concept.</Typography>
        <img alt="" className={classes.wpImage} style={{ width: 400 }} src="/images/newark-pref-label.png" />
        <Typography paragraph variant="body1">In our Newark concept we will now add the old Newark name as an alternative label. Click on the add icon in the ‘Alternative Labels’ panel and add in the detail as in the following screenshot, again making sure to select ‘Create as XL Label’.</Typography>
        <img alt="" className={classes.wpImage} style={{ width: 400 }} src="/images/newark-altlabel.png" />
        <Typography paragraph variant="body1">Our Newark concept should look like the following. Note that the labels have an ‘XL’ icon to indicate they are SKOS-XL labels and not just plain labels.</Typography>
        <img alt="" className={classes.wpImage} style={{ width: 600 }} src="/images/newark-with-labels.png" />
        <Typography paragraph variant="h2" className={classes.wpH2}>Connecting the Labels</Typography>
        <Typography paragraph variant="body1">Click on the text of the ‘Newark Liberty International Airport’ preferred label. That will take you to the details screen for that label, which should look like the following. You should be able to see the two properties from the template being displayed.</Typography>
        <img alt="" className={classes.wpImage} src="/images/newark-with-props.png" />
        <Typography paragraph variant="body1">We will now state that this label replaced the label ‘Newark International Airport’, so in the ‘Replaced Name’ panel click the add icon. You should see a dialog similar to the following image. Click on ‘Newark International Airport’.</Typography>
        <img alt="" className={classes.wpImage} style={{ width: 400 }} src="/images/replaced-name.png" />
        <Typography paragraph variant="body1">In a similar way add a ‘Start Date’ by clicking on the add icon in the ‘Start Date’ panel and entering a date. Once you have done that the screen should look like this:</Typography>
        <img alt="" className={classes.wpImage} src="/images/newark-completed.png" />
        <Typography paragraph variant="body1">You can see that we now have our information connecting our two SKOS-XL labels together via our ‘Replaced Name’ property and that we have stated that the current name had a start date of a particular value.</Typography>
        <Typography paragraph variant="body1">And that’s it! You are now ready to create sophisticated label relationships to add context to your labels.</Typography>
        <div className={classes.wpDocLink} style={{ display: 'flex' }}>
          <div style={{ marginRight: 12 }}>
            <FastForwardIcon fontSize='large' style={{ color: '#97d700' }} />
          </div>
          <div>
            <Typography paragraph variant="body1">The content of this tutorial is very similar to the SKOS-XL example project that you can easily load as part of a Graphologi trial.</Typography>
            <Typography variant='body1'>If you don't fancy going through all the steps above try creating a trial and take a look at that example project.</Typography>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(SkosXLcontext);