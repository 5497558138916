import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import React, { Component } from 'react';
import { globalStyles } from '../style/gotheme';
import PageRow from './PageRow';
import releaseNotes from './releases.json'

const styles = theme => {
  return Object.assign({
    releaseBlock: {
      paddingBottom: 24,
      borderBottom: 'solid 1px #00A9E04D',
      marginBottom: 24
    }
  }, globalStyles)
};

class ReleaseNotesOld extends Component {

  render() {

    const { classes, width, version } = this.props;
    const data = releaseNotes[version];
    const isLarge = width === "md" || width === "lg" || width === "xl";

    return (
      <div>
        {isLarge &&
          <div style={{ position: 'absolute', overflow: 'hidden', width: '100%', right: 0 }}>
            <img
              src="/images/GraphologiIcon.svg"
              style={{
                float: 'right',
                height: 764,
                marginTop: '-100px',
                marginRight: -160
              }}
              alt="" />
          </div>
        }
        <PageRow spaceTop={isLarge ? "100px" : "36px"} spaceBottom={isLarge ? "50px" : "0px"} color={isLarge ? null : "#F2F7FF"}>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h1" style={{ marginBottom: isLarge ? "100px" : "0px" }}>Release Notes</Typography>
            </Grid>
          </Grid>
        </PageRow>
        <PageRow spaceTop={isLarge ? "70px" : "0px"} color="#F2F7FF">
          <Grid container>
            <Grid item xs={12} md={6}>
              <div className={classes.releaseBlock}>
                <Typography variant="h2">{data.title}</Typography>
              </div>
              <div className={classes.releaseBlock}>
                <Typography paragraph variant="h2">Enhancements</Typography>
                {data.enhancements.map((enh, i) => <Typography key={i} paragraph variant="body1">{enh}</Typography>)}
              </div>
              {data.fixes && data.fixes.length > 0 &&
                <div className={classes.releaseBlock}>
                  <Typography paragraph variant="h2">Fixes</Typography>
                  {data.fixes.map((fix, i) => <Typography key={i} paragraph variant="body1">{fix}</Typography>)}
                </div>
              }
            </Grid>
            <Grid item xs={12} md={6}>
            </Grid>
          </Grid>
        </PageRow>
      </div>
    );
  }
}

export default withWidth()(withStyles(styles)(ReleaseNotesOld));