import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import { globalStyles as styles } from '../style/gotheme';
import SkosTutorialLink from './SkosTutorialLink';

const query1 = `PREFIX skos: <http://www.w3.org/2004/02/skos/core#>
SELECT * WHERE {
    ?s a skos:Concept .
}
LIMIT 10`;

const query2 = `PREFIX skos: <http://www.w3.org/2004/02/skos/core#>
SELECT * WHERE {
  ?s a skos:Concept .
  ?s skos:prefLabel ?label .
  FILTER (LANG(?label) = 'en')
}
ORDER BY ?label
LIMIT 10`;

const query3 = `PREFIX skos: <http://www.w3.org/2004/02/skos/core#>
SELECT * WHERE {
  ?s a skos:Concept .
  ?s skos:inScheme <http://skos.um.es/unescothes/CS000> .
  ?s skos:prefLabel ?label .
  FILTER (LANG(?label) = 'en')
}
ORDER BY ?label
LIMIT 10`;

const query4 = `PREFIX dct: <http://purl.org/dc/terms/>
PREFIX skos: <http://www.w3.org/2004/02/skos/core#>
SELECT * WHERE {
  ?s a skos:ConceptScheme .
  ?s dct:title ?title .
}
ORDER BY ?title
LIMIT 10`;

const query5 = `PREFIX dct: <http://purl.org/dc/terms/>
SELECT * WHERE {
  <http://skos.um.es/unescothes/CS000> dct:title ?title .
}
ORDER BY ?title
LIMIT 10`;

const query6 = `PREFIX skos: <http://www.w3.org/2004/02/skos/core#>
SELECT * WHERE {
  ?s a skos:Concept .
  ?s skos:inScheme <http://skos.um.es/unescothes/CS000> .
  ?s skos:prefLabel ?label .
  FILTER (LANG(?label) = 'en')
}
ORDER BY ?label
LIMIT 10`;

const query7 = `PREFIX skos: <http://www.w3.org/2004/02/skos/core#>
SELECT ?s ?label WHERE {
  ?s a skos:Concept .
  ?s skos:inScheme <http://skos.um.es/unescothes/CS000> .
  ?s skos:prefLabel ?label .
  FILTER (LANG(?label) = 'en')
}
ORDER BY ?label
LIMIT 10`;

const query8 = `PREFIX skos: <http://www.w3.org/2004/02/skos/core#>
SELECT ?s ?label WHERE {
  ?s a skos:Concept .
  ?s skos:topConceptOf <http://skos.um.es/unescothes/CS000> .
  ?s skos:prefLabel ?label .
  FILTER (LANG(?label) = 'en')
}
ORDER BY ?label`;

const query9 = `PREFIX skos: <http://www.w3.org/2004/02/skos/core#>
SELECT ?s ?label WHERE {
  <http://skos.um.es/unescothes/C00016> skos:narrower ?s .
  ?s skos:prefLabel ?label .
  FILTER (LANG(?label) = 'en')
}
ORDER BY ?label`;

const query10 = `PREFIX skos: <http://www.w3.org/2004/02/skos/core#>
SELECT ?s ?o WHERE {
  ?s {property} ?o .
}`;

const query11 = `PREFIX skos: <http://www.w3.org/2004/02/skos/core#>
SELECT ?s ?label WHERE {
  ?s a skos:Concept .
  OPTIONAL {
    ?s skos:altLabel ?label .
    FILTER (LANG(?label) = 'en')
  }
}
ORDER BY DESC(LCASE(?label))
LIMIT 10`;

class BasicSPARQL extends Component {

  render() {

    const { classes } = this.props;

    return (
      <div>
<Typography paragraph variant="body1">These days taxonomy management systems, such as Graphologi, use a set of standards to store data. These are SKOS, SKOS-XL and OWL.</Typography>        
<SkosTutorialLink/>
<Typography paragraph variant="body1">Behind the standards is something called <a className={classes.wpLink} href="https://www.w3.org/TR/rdf11-primer/" target="_new">RDF</a> (Resource Description Framework). RDF data is stored in something called a ‘triple store’. Triples stores can almost always be queried using a language called <a className={classes.wpLink} href="https://www.w3.org/TR/sparql11-query/" target="_new">SPARQL</a>. SPARQL is a very powerful language, but can be a bit intimidating to start with.</Typography>
<Typography paragraph variant="body1">With regard to taxonomies, it can be very useful to be able to use SPARQL to query the data behind them for various reasons. One reason is for integration purposes, as SPARQL gives the most flexibility in terms of asking for the data that an integration requires. The second relates to a feature in Graphologi - reporting - which is based upon SPARQL queries. If you can write some SPARQL you can create all sorts of reports for different purposes - additional business rules, quality checks, or management reporting.</Typography>
<Typography paragraph variant="body1">This tutorial covers some of the basics of SPARQL that will be helpful in writing queries aimed at generating reports in Graphologi, but the principles are applicable more widely.</Typography>
<Typography paragraph variant="body1">Within Graphologi you can create and run SPARQL queries using the SPARQL dashboard.</Typography>
<Typography paragraph variant="body1">What does a SPARQL query look like? Here is a very basic one:</Typography>
<pre className={classes.wpCode}>{query1}</pre>
<Typography paragraph variant="body1">What is going on here? Well, this query is saying ‘give me back 10 SKOS concepts’, or, to be more precise, the IRI of those concepts.</Typography>
<Typography paragraph variant="body1">There are several key aspects:</Typography>
<ul className={classes.wpList}>
  <li>
<Typography paragraph variant="body1">The first line is something called a prefix statement. This simply allows you to write queries in a more compact way. Without prefixes you have to enter full IRIs which can be time consuming and less readable. Here we have a prefix for the SKOS namespace - <span className={classes.wpInlineCode}>skos</span> - and you can see we have used it on line 3. In Graphologi it will automatically detect well known prefixes and add in the <span className={classes.wpInlineCode}>PREFIX</span> line - just start typing skos: and the rest just happens - meaning you don’t have to remember them.</Typography>
</li>
<li>
<Typography paragraph variant="body1">The second line defines the type of query and the results we want back. <span className={classes.wpInlineCode}>SELECT</span> is the standard query for most purposes. <span className={classes.wpInlineCode}>SELECT * WHERE</span> means ‘give me back everything’. Often you don’t want to do that but for this simple example it’s fine.</Typography>
</li>
<li>
<Typography paragraph variant="body1">The third line is the interesting bit! This is saying find all things that are concepts. <span className={classes.wpInlineCode}>?s</span> is a variable that will be populated with results. The <span className={classes.wpInlineCode}>a</span> in the middle is shorthand for type and <span className={classes.wpInlineCode}>skos:Concept</span> is using our prefix to say SKOS concepts. Note the period (full stop) at the end of the line. This is important when you have multiple lines in this part of the query.</Typography>
</li>
<li>
<Typography paragraph variant="body1">The last line is restricting the query to 10 results and it is always a good idea, certainly when first writing a query to put a <span className={classes.wpInlineCode}>LIMIT</span> on it to avoid queries running amok (which can happen).</Typography>
</li>
</ul>
<Typography paragraph variant="body1">Let’s write something a bit more useful…</Typography>
<pre className={classes.wpCode}>{query2}</pre>
<Typography paragraph variant="body1">This query is asking for 10 concepts where the results will include the IRI, the preferred label in English and where the labels are ordered.</Typography>
<Typography paragraph variant="body1">To explain some of the additional parts this query contains:</Typography>
<ul>
<Typography paragraph variant="body1">Lines 3 and 4 contain what is called a ‘join’. This happens because they both use the variable <span className={classes.wpInlineCode}>?s</span>. What this means is that results must have both a type of <span className={classes.wpInlineCode}>skos:Concept</span> and a preferred label - <span className={classes.wpInlineCode}>skos:prefLabel</span> is the property for that.</Typography>
<Typography paragraph variant="body1">Generally the lines with variables form one of a few patterns, e.g. variable name -&gt; property -&gt; variable name, or variable name -&gt; property -&gt; value.</Typography>
<Typography paragraph variant="body1">The <span className={classes.wpInlineCode}>FILTER</span> line is saying ‘filter labels to only those that have a language code of ‘en’.</Typography>
<Typography paragraph variant="body1">The <span className={classes.wpInlineCode}>ORDER BY</span> line is saying order by the values in the <span className={classes.wpInlineCode}>?label</span> variable (i.e. order preferred labels)</Typography>
</ul>
<Typography paragraph variant="body1">One of the issues with the last query is that it will return concepts from all taxonomies (concept schemes). Most of the time we probably want to narrow it down to a single taxonomy. So let’s do that with the next query.</Typography>
<pre className={classes.wpCode}>{query3}</pre>
<Typography paragraph variant="body1">In the above query we have added another ‘graph pattern’. This introduces another useful property - <span className={classes.wpInlineCode}>skos:inScheme</span> - which every concept that belongs to a taxonomy will have. In this example our query uses the <a className={classes.wpLink} href="https://skos.um.es/unescothes/" target="_new">UNESCO thesaurus</a>. The bold text is the <a className={classes.wpLink} href="https://en.wikipedia.org/wiki/Internationalized_Resource_Identifier" target="_new">IRI</a> of the UNESCO thesaurus. The IRI of a taxonomy is the identifier for it. It is also the IRI you enter when creating a taxonomy in Graphologi and can be found on the details screen, as shown below.</Typography>
<img alt="UNESCO detail" className={classes.wpImage} src="/images/unesco-detail.png" />
<Typography paragraph variant="body1">If you’re wondering why <span className={classes.wpInlineCode}>skos:inScheme</span>, then the reason for that is that, in SKOS, there is a thing called a ‘concept scheme’, which is the technical name for what we call a taxonomy. Concept schemes have a type similar to that which we used for our concepts, but for concept schemes it is <span className={classes.wpInlineCode}>skos:ConceptScheme</span>.</Typography>
<Typography paragraph variant="body1">If you want to retrieve all the taxonomies in a project in Graphologi you need a query similar to the following:</Typography>
<pre className={classes.wpCode}>{query4}</pre>
<Typography paragraph variant="body1">In the above we have a new prefix, whilst line 4 contains the pattern looking for concept schemes and line 5 uses a new property - <span className={classes.wpInlineCode}>dct:title</span> - which, in Graphologi, is the property that all taxonomies with title use. The dct namespace is <a className={classes.wpLink} href="https://www.dublincore.org/specifications/dublin-core/dcmi-terms/" target="_new">Dublin Core Terms</a> - a very widely used metadata model.</Typography>
<Typography paragraph variant="body1">One other thing to note about this query is that we have taken the language filter off, so this will return all the titles for each taxonomy.</Typography>
<Typography paragraph variant="body1">What if we wanted to get all the titles for the UNESCO taxonomy. We need a query like the following:</Typography>
<pre className={classes.wpCode}>{query5}</pre>
<Typography paragraph variant="body1">This introduces a new idea - using IRIs on the left of a graph pattern. As we know the IRI of the UNESCO taxonomy we can directly ask for the titles of it.</Typography>
<Typography paragraph variant="body1">Let’s revisit the following query:</Typography>
<pre className={classes.wpCode}>{query6}</pre>
<Typography paragraph variant="body1">By default this query will return rows of results similar to the following.</Typography>
<img alt="UNESCO SPARQL query" className={classes.wpImage} src="/images/unesco-sparql-1.png" />
<Typography paragraph variant="body1">As we used <span className={classes.wpInlineCode}>SELECT *</span> this returns all the ‘bound’ variables. Bound simply means when a variable has a value.</Typography>
<Typography paragraph variant="body1">We can write this query in a different way to be more explicit about the values we want returned. Take a look at the second line in the following example.</Typography>
<pre className={classes.wpCode}>{query7}</pre>
<Typography paragraph variant="body1">Note how we now have <span className={classes.wpInlineCode}>SELECT ?s ?label</span>, which means we are telling the query to return just the values for <span className={classes.wpInlineCode}>?s</span> and <span className={classes.wpInlineCode}>?label</span> in each row of the results. This will actually give us the same results as the previous query because we only have two results. However, if you change it to <span className={classes.wpInlineCode}>SELECT ?label</span> then you would just get back the labels.</Typography>
<img alt="UNESCO SPARQL query" className={classes.wpImage} src="/images/unesco-sparql-2.png" />
<Typography paragraph variant="body1">It is generally a good idea to be explicit in the results you want, because it will potentially reduce the number of results and improve the performance of queries.</Typography>
<Typography paragraph variant="body1">We will now cover some common properties. First is getting the top concepts in a taxonomy.</Typography>
<pre className={classes.wpCode}>{query8}</pre>
<Typography paragraph variant="body1">The following gets the narrower concepts of a particular concept - http://skos.um.es/unescothes/C00016. Replace the IRI of the concept to that which you want to use.</Typography>
<pre className={classes.wpCode}>{query9}</pre>
<Typography paragraph variant="body1">The following table lists some of the other common SKOS properties you might want to use.</Typography>
<Table className={classes.wpTable}>
  <TableHead>
    <TableRow className={classes.wpTableHeadingRow}>
      <TableCell><Typography className={classes.wpTableHeading}>Property</Typography></TableCell>
      <TableCell><Typography className={classes.wpTableHeading}>Description</Typography></TableCell>
    </TableRow>
  </TableHead>
  <TableBody>
    <TableRow>
      <TableCell className={classes.wpTableText}><span className={classes.wpInlineCode}>skos:broader</span></TableCell>
      <TableCell className={classes.wpTableText}>Broader concepts. This property is the inverse of <span className={classes.wpInlineCode}>skos:narrower</span>.</TableCell>
    </TableRow>
    <TableRow>
      <TableCell className={classes.wpTableText}><span className={classes.wpInlineCode}>skos:altLabel</span></TableCell>
      <TableCell className={classes.wpTableText}>Alternative label.</TableCell>
    </TableRow>
    <TableRow>
      <TableCell className={classes.wpTableText}><span className={classes.wpInlineCode}>skos:hiddenLabel</span></TableCell>
      <TableCell className={classes.wpTableText}>Hidden label.</TableCell>
    </TableRow>
    <TableRow>
      <TableCell className={classes.wpTableText}><span className={classes.wpInlineCode}>skos:related</span></TableCell>
      <TableCell className={classes.wpTableText}>This property is used for related concepts in the same taxonomy.</TableCell>
    </TableRow>
    <TableRow>
      <TableCell className={classes.wpTableText}><span className={classes.wpInlineCode}>skos:relatedMatch</span></TableCell>
      <TableCell className={classes.wpTableText}>Related concepts in other taxonomies.</TableCell>
    </TableRow>
    <TableRow>
      <TableCell className={classes.wpTableText}><span className={classes.wpInlineCode}>skos:exatchMatch</span></TableCell>
      <TableCell className={classes.wpTableText}>Concepts in other taxonomies that are considered equivalent.</TableCell>
    </TableRow>
    <TableRow>
      <TableCell className={classes.wpTableText}><span className={classes.wpInlineCode}>skos:closeMatch</span></TableCell>
      <TableCell className={classes.wpTableText}>Concept is other taxonomies that are closely related.</TableCell>
    </TableRow>
    <TableRow>
      <TableCell className={classes.wpTableText}><span className={classes.wpInlineCode}>skos:changeNote</span></TableCell>
      <TableCell className={classes.wpTableText}>Notes detailing changes to a concept.</TableCell>
    </TableRow>
    <TableRow>
      <TableCell className={classes.wpTableText}><span className={classes.wpInlineCode}>skos:historyNote</span></TableCell>
      <TableCell className={classes.wpTableText}>Notes detailing the history of a concept.</TableCell>
    </TableRow>
    <TableRow>
      <TableCell className={classes.wpTableText}><span className={classes.wpInlineCode}>skos:definition</span></TableCell>
      <TableCell className={classes.wpTableText}>A definition of a concept.</TableCell>
    </TableRow>
  </TableBody>
</Table>
<Typography paragraph variant="body1">The general pattern to use these properties is the same as the other examples, as shown in the following example. Simply replace <span className={classes.wpInlineCode}>&#123;property&#125;</span> with the relevant property from the table.</Typography>
<pre className={classes.wpCode}>{query10}</pre>
<Typography paragraph variant="body1">You can combine patterns together in a query as we showed further above, but remember that all the patterns must occur for a result to be included. If you think that some of the patterns may not be present then you can include the pattern using the <span className={classes.wpInlineCode}>OPTIONAL</span> keyword, as in the following example:</Typography>
<pre className={classes.wpCode}>{query11}</pre>
<Typography paragraph variant="body1">The above will include results whether or not they have alternative labels. You can include multiple <span className={classes.wpInlineCode}>OPTIONAL</span> clauses if there are multiple properties that may not exist. Note that we are ordering our results by descending (<span className={classes.wpInlineCode}>DESC</span>) lower case (<span className={classes.wpInlineCode}>LCASE</span>) labels.</Typography>
<Typography paragraph variant="body1">The order of patterns and statements in a query is important, but for the vast majority of queries the right order naturally occurs due to how you think about the query. That is, patterns, followed by filters, followed by <span className={classes.wpInlineCode}>OPTIONAL</span> clauses (which may also include filters).</Typography>
<Typography paragraph variant="body1">If you are comfortable with all that you have read above, then the next step is to read the official SKOS primer at:</Typography>
<Typography paragraph variant="body1" align="center"><a className={classes.wpLink} href="https://www.w3.org/TR/skos-primer/" target="_new">https://www.w3.org/TR/skos-primer/</a></Typography>
<Typography paragraph variant="body1">This will give you a thorough introduction to SKOS and how it is represented in RDF.</Typography>
      </div>
    );
  }
}

export default withStyles(styles)(BasicSPARQL);