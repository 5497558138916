import withWidth from '@material-ui/core/withWidth';
import StandardAppBar from './components/StandardAppBar';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { gotheme } from './style/gotheme';
import StandardFooter from './components/StandardFooter';
import Home from './components/Home';
import Pricing from './components/Pricing';
import Learn from './components/Learn';
import Contact from './components/Contact';
import NotFound from './components/NotFound';
import Conditions from './components/Conditions';
import FAQ from './components/FAQ';
import Privacy from './components/Privacy';
import React, { Component } from 'react';
import Testimonials from './components/Testimonials';
import ScrollToTop from './components/ScrollToTop';
import ReleaseNotes from './components/ReleaseNotes';
import ReleaseNotesOld from './components/ReleaseNotesOld';
import GettingStartedWithStandards from './components/GettingStartedWithStandards';
import OrganisingSubsetsOfInformation from './components/OrganisingSubsetsOfInformation';
import SkosXLcontext from './components/SkosXLcontext';
import BasicSPARQL from './components/BasicSPARQL';
import TutorialWrapper from './components/TutorialWrapper';
import ElementsOfKG from './components/ElementsOfKG';

const tutorials = [
  {
    url: "elements-of-a-knowledge-graph",
    docComponent: ElementsOfKG,
    title: <span>Elements of a<br />Knowledge Graph</span>,
    description: "Knowledge graphs are everywhere. Find out what makes up a knowledge graph and how things connect together."
  },
  {
    url: "getting-started-with-standards",
    docComponent: GettingStartedWithStandards,
    title: "Getting Started with Standards In Graphologi",
    description: "Many companies still manage taxonomies in spreadsheets or using in-house systems. Working in this way has potential problems such as the maintenance and interoperability of any data. Find out more about why and how to use standards in Graphologi."
  },
  {
    url: "organising-subsets-of-information",
    docComponent: OrganisingSubsetsOfInformation,
    title: "Organising Subsets of Information",
    description: "What are the options and what is the best way to organise subsets of information in taxonomies. This tutorial covers the various options available when working with SKOS."
  },
  {
    url: "skos-xl-context",
    docComponent: SkosXLcontext,
    title: "Adding Context to Labels",
    description: "Sometimes taxonomy labels need additional context to describe when they should be used. This tutorial covers the use of SKOS-XL to handle this situation."
  },
  {
    url: "basic-sparql-taxonomies",
    docComponent: BasicSPARQL,
    longTitle: true,
    title: "An Introduction to Querying Taxonomies Using SPARQL",
    description: "One of the most useful ways to query taxonomy data stored as RDF is to use SPARQL. This is a brief introduction for those new to SPARQL."
  }
]

const theme = createMuiTheme(gotheme);

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showMenu: false
    };
  }

  goToGraphologi = (page) => {
    if (page === "Contact") {
      this.props.history.push("contact")
    }
    else {
      window.location.href = "https://graphologi.graphifi.com";
    }
  }

  showMenu = () => this.setState({ showMenu: true });

  hideMenu = () => this.setState({ showMenu: false });

  render() {

    const { width } = this.props;
    const { showMenu } = this.state;
    const isLarge = width !== "xs" && width !== "sm" && width !== "md";

    return (
      <Router>
        <ScrollToTop>
          <MuiThemeProvider theme={theme}>
            {showMenu ?
              <StandardAppBar blend={isLarge} showMenu={showMenu} onShowMenu={this.showMenu} onHideMenu={this.hideMenu} />
              :
              <React.Fragment>
                <Switch>
                  <Route exact path="/" render={(props) =>
                    <div style={{
                      ...(isLarge) && {
                        height: 840,
                        background: 'radial-gradient(at center, #1C4B8C, #142B4B)'
                      }
                    }}>
                      {isLarge &&
                        <React.Fragment>
                          <img style={{
                            position: 'absolute',
                            top: 0,
                            right: 0,
                          }} src="/images/bluehintright.svg" />
                          <img style={{
                            position: 'absolute',
                            top: 260,
                          }} src="/images/bluehint.svg" />
                          <img style={{
                            position: 'absolute',
                            top: 360,
                            right: 0
                          }} src="/images/greenhint.svg" />
                        </React.Fragment>
                      }
                      <StandardAppBar blend={isLarge} showMenu={showMenu} onShowMenu={this.showMenu} onHideMenu={this.hideMenu} />
                      <Home {...props} />
                      <StandardFooter />
                    </div>
                  } />
                  <Route render={() => <div>
                    <StandardAppBar showMenu={showMenu} onShowMenu={this.showMenu} onHideMenu={this.hideMenu} />
                    {!showMenu &&
                      <React.Fragment>
                        <Switch>
                          <Route exact path="/pricing" render={(props) => <Pricing goTo={this.goToGraphologi} {...props} />} />
                          <Route exact path="/learn" render={(props) => <Learn
                            tutorials={tutorials}
                            {...props} />} />
                          <Route exact path="/contact" render={(props) => <Contact {...props} />} />
                          <Route exact path="/tandc" render={(props) => <Conditions {...props} />} />
                          <Route exact path="/faq" render={(props) => <FAQ {...props} />} />
                          <Route exact path="/privacy" render={(props) => <Privacy {...props} />} />
                          <Route exact path="/release" render={(props) => <ReleaseNotes {...props} />} />
                          <Route path="/release/" render={(props) => <ReleaseNotesOld {...props} version={props.location.pathname.substring(9)} />} />
                          <Route exact path="/testimonial" render={(props) => <Testimonials {...props} />} />
                          {tutorials.map((tutorial, index) => {
                            const DocComponent = tutorial.docComponent;
                            return <Route
                              key={index}
                              exact path={"/" + tutorial.url}
                              render={(props) => <TutorialWrapper
                                title={tutorial.title}
                                description={tutorial.description}>
                                <DocComponent />
                              </TutorialWrapper>} />
                          })}
                          <Route render={() => <NotFound />} />
                        </Switch>
                        <StandardFooter />
                      </React.Fragment>
                    }
                  </div>} />
                </Switch>
              </React.Fragment>
            }
          </MuiThemeProvider>
        </ScrollToTop>
      </Router>
    );
  }
}

export default withWidth()(App);
