import withWidth from '@material-ui/core/withWidth';
import Tooltip from '@material-ui/core/Tooltip';
import React from 'react';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { globalStyles as styles } from '../style/gotheme';
import PropTypes from 'prop-types';

class PrimaryButton extends React.Component {

  render() {

    const { classes, onClick, title, disabled, fixedWidth, compact } = this.props;

    const button = <Button
      data-test={this.props.dataTest}
      variant="contained"
      color="secondary"
      disabled={disabled}
      onClick={onClick}
      style={compact ?
        { padding: '12px 24px' }
        :
        {}
      }
      className={fixedWidth ? classes.fixedWidthButton : classes.button}
    >
      {this.props.children}
    </Button>

    return (
      title ?
        <Tooltip title={title}>
          <span>
            {button}
          </span>
        </Tooltip>
        :
        button
    );
  }
}

PrimaryButton.defaultProps = {
  disabled: false,
  fixedWidth: false,
  compact: false,
  dataTest: "primary-action"
}

PrimaryButton.propTypes = {
  dataTest: PropTypes.string,
  compact: PropTypes.bool,
  fixedWidth: PropTypes.bool,
  children: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  title: PropTypes.string
}

export default withWidth()(withStyles(styles)(PrimaryButton))