import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import { globalStyles as styles } from '../style/gotheme';
import withWidth from '@material-ui/core/withWidth';
import Grid from '@material-ui/core/Grid';
import PageRow from './PageRow';

class TutorialWrapper extends Component {

  render() {

    const { width, title, children } = this.props;
    const isLarge = width === "md" || width === "lg" || width === "xl";

    return (
      <div>
        {isLarge &&
          <div style={{ position: 'absolute', overflow: 'hidden', width: '100%', right: 0 }}>
            <img
              src="/images/GraphologiIcon.svg"
              style={{
                float: 'right',
                height: 764,
                marginTop: '-100px',
                marginRight: -160
              }}
              alt="" />
          </div>
        }
        <PageRow spaceTop={isLarge ? "100px" : "36px"} spaceBottom="50px">
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h1">Tutorial</Typography>
              <Typography variant="h2">{title}</Typography>
            </Grid>
          </Grid>
        </PageRow>
        <PageRow spaceTop="70px" color="#F2F7FF">
          <div style={{
            maxWidth: '748px', margin: '24px auto', backgroundColor: 'white', padding: 24, boxShadow: '0px 1px 3px #00000029'
          }}>
            {children}
          </div>
        </PageRow>
      </div>
    );
  }
}

export default withWidth()(withStyles(styles)(TutorialWrapper));