import withWidth from '@material-ui/core/withWidth';
import { LinearProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { globalStyles } from '../style/gotheme';

const styles = theme => {
  return Object.assign({
    description: {
      fontFamily: 'Roboto',
      fontSize: 50,
      color: "#13294B",
      margin: '10px 0px 0px 0px',
      fontWeight: 400
    },
    progress: {
      width: '100%',
      height: '4px',
      marginLeft: 'auto',
      marginRight: 'auto'
    },
    title: {
      fontFamily: 'Roboto',
      fontSize: 34,
      fontWeight: 600,
      color: "#13294B",
      margin: '12px 0px'
    },
    titleNarrow: {
      fontFamily: 'Roboto',
      fontSize: 20,
      margin: '12px 0px 0px 0px',
      fontWeight: 600,
      color: "#13294B",
      margin: '12px 0px'
    },
    descriptionNarrow: {
      fontFamily: 'Roboto',
      fontSize: 16,
      color: "#13294B",
      margin: '0px 0px 24px 0px',
      fontWeight: 400
    },
    titleContainer: {
      width: 416
    }
  }, globalStyles)
};

class RollingText extends Component {

  state = {
    showing: 0,
    progressCount: 0
  }

  updateBar = () => {
    if (this.showingTimeout) clearTimeout(this.showingTimeout)
    let progressCount = this.state.progressCount + 1;
    let showing = this.state.showing;
    if (progressCount === 101) {
      progressCount = 0;
      showing = this.state.showing + 1;
      if (showing === this.props.items.length) showing = 0;
    }
    this.setState({ showing, progressCount })
    this.showingTimeout = setTimeout(this.updateBar, 50);
  }

  componentDidMount() {
    this.showingTimeout = setTimeout(this.updateBar, 100);
  }

  componentWillUnmount() {
    if (this.showingTimeout) clearTimeout(this.showingTimeout)
  }

  changeShowing = (showing) => this.setState({ showing, progressCount: 0 });

  render() {

    const { classes, items, width } = this.props;
    const { showing, progressCount } = this.state;

    if (width !== "lg" && width !== "md") {
      return (
        <div style={{margin: '0px 30px'}}>
          {items.map((item, index) =>
            <div key={index}>
              {index > 0 && <div style={{ borderTop: 'solid 4px #e0e0e0' }} />}
              <p className={classes.titleNarrow}>
                {item.title}
              </p>
              <p className={classes.descriptionNarrow}>
                {items[index].description}
              </p>
            </div>
          )}
        </div >
      );
    }

    return (
      <div style={{ display: 'flex' }}>
        <div style={{ width: '40%' }}>
          {items.map((item, index) =>
            <div key={index}>
              {index === showing ?
                <LinearProgress
                  color="secondary"
                  className={classes.progress}
                  variant="determinate"
                  value={index === showing ? progressCount : 0} />
                :
                <div style={{ borderTop: 'solid 4px #e0e0e0' }} />
              }
              <p className={classes.title}
                style={{
                  ...(index === showing) && { color: "#13294B" },
                  ...(index !== showing) && { opacity: 0.4 }
                }}
                onClick={() => this.changeShowing(index)}>
                {item.title}
              </p>
            </div>
          )}
        </div>
        <div style={{ width: '50%', marginLeft: '10%' }}>
          <p className={classes.description}>
            {items[showing].description}
          </p>
        </div>
      </div >
    );
  }
}

RollingText.propTypes = {
  items: PropTypes.array.isRequired
}

export default withWidth()(withStyles(styles)(RollingText));