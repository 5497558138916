import { withRouter } from 'react-router-dom';
import { Badge, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import withWidth from '@material-ui/core/withWidth';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { globalStyles, gotheme } from '../style/gotheme';
import PageRow from './PageRow';
import PricingFAQ from './PricingFAQ';
import PrimaryButton from './PrimaryButton';
import ButtonSwitch from './ButtonSwitch';

const styles = theme => {
  return Object.assign({
    tableColumnHeading: {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
      fontSize: '16px',
      width: '180px',
      lineHeight: '22px',
      fontWeight: 400,
      fontFamily: 'Roboto',
      border: 'solid 1px #00A9E04D'
    },
    tableColumnHeadingSmall: {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
      fontSize: '16px',
      width: '30%',
      lineHeight: '22px',
      fontWeight: 400,
      fontFamily: 'Roboto',
      border: 'solid 1px #00A9E04D'
    },
    yearly: {
      fontSize: "16px",
      fontFamily: "Roboto",
      lineHeight: "26px",
      fontWeight: 400,
      color: "#616161"
    },
    tableText: {
      color: theme.palette.primary.main,
      fontSize: '16px',
      lineHeight: '22px',
      fontWeight: 400,
      fontFamily: 'Roboto',
      verticalAlign: 'top',
      borderLeft: 'solid 1px #00A9E04D',
      borderBottom: 'solid 1px #00A9E04D'
    },
    tableTextHighlight: {
      backgroundColor: 'rgb(0, 169, 224, 0.05)',
      color: theme.palette.primary.main,
      fontSize: '16px',
      lineHeight: '22px',
      fontWeight: 400,
      fontFamily: 'Roboto',
      verticalAlign: 'top',
      borderLeft: 'solid 1px #00A9E04D',
      borderBottom: 'solid 1px #00A9E04D'
    },
    tableCell: {
      verticalAlign: 'top',
      borderLeft: 'solid 1px #00A9E04D',
      borderBottom: 'solid 1px #00A9E04D'
    },
    tableCellText: {
    },
    tableCellSmall: {
      verticalAlign: 'bottom',
      borderBottom: 'solid 1px #00A9E04D'
    },
    tableHeading: {
      fontSize: "24px",
      lineHeight: "34px",
      fontWeight: 500,
      fontFamily: 'rajdhani',
      color: "#13294B"
    }
  }, globalStyles)
};

const products = [
  {
    name: "Standard",
    description: "A cost-effective option specifically for start-ups.",
    maxUsers: "3",
    price: 250,
    dollarPrice: 350,
    priceYearly: 2750,
    dollarPriceYearly: 3850,
    smallButtonText: "Start",
    buttonText: "Get Started",
    collaboration: true,
    capacity: "Shared",
    resilience: "High availability",
    service: ">99.5%",
    support: "8x5** Response within 1 business day",
    data: "Small",
    integrations: 4,
    maxImportSize: "50",
    reporting: false,
    versions: false,
    notifications: false,
    bulkEdit: false,
    customDeploymentRegion: false,
    discountOnExtraEnvironment: false,
    customInfra: false
  },
  {
    name: "Premium",
    description: "Includes all available features and additional limits across the board.",
    maxUsers: "50",
    price: 1000,
    highlight: true,
    dollarPrice: 1400,
    priceYearly: 11000,
    dollarPriceYearly: 15400,
    smallButtonText: "Start",
    buttonText: "Get Started",
    collaboration: true,
    capacity: "Partially Dedicated",
    resilience: "High availability",
    service: ">99.5%",
    support: "24x7 Response within 12 hours",
    data: "Large",
    integrations: 20,
    maxImportSize: "100+",
    reporting: true,
    versions: true,
    notifications: true,
    bulkEdit: true,
    training: 'Half day',
    customDeploymentRegion: false,
    discountOnExtraEnvironment: true,
    customInfra: false
  },
  {
    name: "Ultimate",
    description: "For those customers who want a dedicated setup and/or who need single sign-on.",
    maxUsers: "Unlimited",
    highlight: true,
    dollarPrice: 2800,
    priceYearly: 22000,
    dollarPriceYearly: 30800,
    smallButtonText: "Contact",
    buttonText: "Contact",
    collaboration: true,
    capacity: "Totally independent",
    resilience: "High availability",
    service: ">99.5",
    support: "24x7 Response within 12 hours",
    data: "Large",
    integrations: 100,
    maxImportSize: "500",
    reporting: true,
    versions: true,
    notifications: true,
    bulkEdit: true,
    sso: true,
    customDomain: true,
    customTerms: true,
    training: 'Half day',
    customDeploymentRegion: true,
    discountOnExtraEnvironment: true,
    customInfra: true
  }
]

const MONTH = "month";
const YEAR = "year";
const SUBSCRIPTION = "subscription";
const MANAGED = "managed";
const ON_PREMISE = "onpremise";

class Pricing extends Component {

  constructor(props) {
    super(props);
    this.state = {
      timePeriod: MONTH,
      serviceType: SUBSCRIPTION
    };
  }

  selectTimePeriod = (index) => {
    const timePeriod = index === 0 ? MONTH : YEAR
    this.setState({ timePeriod })
  }

  selectServiceType = (index) => {
    let serviceType = SUBSCRIPTION;
    if (index === 1) serviceType = MANAGED;
    if (index === 2) serviceType = ON_PREMISE;
    this.setState({ serviceType })
  }

  goToGraphologi = (page) => {
    if (page === "Contact") {
      this.props.history.push("contact")
    }
    else {
      window.location.href = "https://graphologi.graphifi.com/gs/signup";
    }
  }

  render() {

    const { classes, width } = this.props;
    const { timePeriod, serviceType } = this.state;
    const isLarge = width === "lg" || width === "xl";

    return (
      <div style={{ backgroundColor: '#F2F7FF' }}>
        {isLarge &&
          <div style={{ position: 'absolute', overflow: 'hidden', width: '100%', right: 0 }}>
            <img
              src="/images/GraphologiIcon.svg"
              style={{
                float: 'right',
                height: 764,
                marginTop: '-100px',
                marginRight: -260
              }}
              alt="" />
          </div>
        }
        <PageRow spaceTop={isLarge ? "100px" : "36px"}>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h1" style={{ marginBottom: isLarge ? "40px" : "20px" }}>Prices Built for<br />Every User</Typography>
            </Grid>
            <Grid item xs={12}>
              <div style={{ backgroundColor: '#ffffff', marginBottom: 60, borderRadius: 4, paddingTop: 12, boxShadow: '0px 6px 12px #00000029', opacity: 0.9, paddingLeft: 20, paddingRight: 20, paddingBottom: 20 }}>
                <div style={{ marginBottom: 20 }}>
                  <Typography align="center" variant="h2" style={{ marginBottom: "20px" }}>Options for Purchasing</Typography>
                  <ButtonSwitch
                    backgroundColor="primary"
                    buttonWidth="532px"
                    onSelect={this.selectServiceType}
                    options={["Subscription", "Managed Service", "On Premise"]}
                    selected={serviceType === SUBSCRIPTION ?
                      0
                      :
                      serviceType === MANAGED ?
                        1
                        :
                        2} />
                </div>
              </div>
            </Grid>
            {serviceType === ON_PREMISE &&
              <Grid item xs={12}>
                <Table
                  data-test="pricing"
                  style={{
                    marginBottom: 20,
                    backgroundColor: '#ffffff',
                    borderRadius: '4px',
                    boxShadow: '0px 6px 12px #00000029'
                  }}>
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan="2" className={classes.tableCell}>
                        <Typography className={classes.tableHeading}>On Premise</Typography>
                        <React.Fragment>
                          <Typography variant="h2"><span style={{ fontSize: 20 }}>Call for licence costs</span></Typography>
                        </React.Fragment>
                        <div style={{ flex: 0 }}>
                          <PrimaryButton onClick={() => this.goToGraphologi('Contact')}>Contact</PrimaryButton>
                        </div>
                        <Typography className={classes.yearly} style={{ fontSize: 14 }}>Infrastructure costs paid by customer. Customer can choose the level of infrastructure needed to support the required number of users.</Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell className={classes.tableColumnHeadingSmall}>Users</TableCell>
                      <TableCell className={classes.tableText}>Unlimited</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.tableColumnHeadingSmall}>Single sign-on</TableCell>
                      <TableCell className={classes.tableText}>Available</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.tableColumnHeadingSmall}>Collaboration</TableCell>
                      <TableCell className={classes.tableText}>
                        <CheckCircleIcon style={{ color: gotheme.palette.secondary.main }} />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.tableColumnHeadingSmall}>Environments</TableCell>
                      <TableCell className={classes.tableText}>
                        <Typography paragraph className={classes.tableCellText}>The licence is for a production environment, which can be hosted under a customer's domain name if required</Typography>
                        <Typography className={classes.tableCellText}>Additional environments can be provided at a reduced cost per environment</Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.tableColumnHeadingSmall}>Capacity</TableCell>
                      <TableCell className={classes.tableText}>Configurable and will depend upon infrastructure used for deployment.</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.tableColumnHeadingSmall}>Resilience and Security</TableCell>
                      <TableCell className={classes.tableText}>
                        <Typography className={classes.tableCellText}>The level of resilience and any security requirements can be discussed. There are many options that cover different scenarios</Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.tableColumnHeadingSmall}>Service Level</TableCell>
                      <TableCell className={classes.tableText}>Depends upon configuration.</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.tableColumnHeadingSmall}>Support (Email)</TableCell>
                      <TableCell className={classes.tableText}>
                        <Typography paragraph className={classes.tableCellText}>24x7 Response within 12 hours</Typography>
                        <Typography className={classes.tableCellText}>Please contact us to discuss further options</Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.tableColumnHeadingSmall} style={{ borderBottomLeftRadius: 4 }}>Data</TableCell>
                      <TableCell className={classes.tableText}>Configurable and only limited by customer's choice of infrastructure</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.tableColumnHeadingSmall} style={{ borderBottomLeftRadius: 4 }}>Webhook Integrations</TableCell>
                      <TableCell className={classes.tableText}>Unlimited (each integration incurs a small infrastructure cost per month)</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.tableColumnHeadingSmall} style={{ borderBottomLeftRadius: 4 }}>Maximum Import Size (MB)</TableCell>
                      <TableCell className={classes.tableText}>500</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Grid>
            }
            {serviceType === MANAGED &&
              <Grid item xs={12}>
                <Table
                  data-test="pricing"
                  style={{
                    marginBottom: 20,
                    backgroundColor: '#ffffff',
                    borderRadius: '4px',
                    boxShadow: '0px 6px 12px #00000029'
                  }}>
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan="2" className={classes.tableCell}>
                        <Typography className={classes.tableHeading}>Managed Service</Typography>
                        <React.Fragment>
                          <Typography variant="h2"><span style={{ fontSize: 20 }}>From</span> £24000 <span style={{ fontSize: 20 }}>per year</span></Typography>
                          <Typography variant="h2"><span style={{ fontSize: 20 }}>From</span> $30000 <span style={{ fontSize: 20 }}>per year</span></Typography>
                        </React.Fragment>
                        <Typography paragraph className={classes.yearly} style={{ fontSize: 14 }}>(Excl. of Sales Tax)</Typography>
                        <div style={{ flex: 0 }}>
                          <PrimaryButton onClick={() => this.goToGraphologi('Contact')}>Contact</PrimaryButton>
                        </div>

                        <Typography className={classes.yearly} style={{ fontSize: 14 }}>Infrastructure costs paid by customer. Customer can choose the level of infrastructure needed to support the required number of users.</Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell className={classes.tableColumnHeadingSmall}>Users</TableCell>
                      <TableCell className={classes.tableText}>Unlimited</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.tableColumnHeadingSmall}>Single sign-on</TableCell>
                      <TableCell className={classes.tableText}>Available</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.tableColumnHeadingSmall}>Collaboration</TableCell>
                      <TableCell className={classes.tableText}>
                        <CheckCircleIcon style={{ color: gotheme.palette.secondary.main }} />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.tableColumnHeadingSmall}>Environments</TableCell>
                      <TableCell className={classes.tableText}>
                        <Typography paragraph className={classes.tableCellText}>The licence is for a production environment, which can be hosted under a customer's domain name if required</Typography>
                        <Typography className={classes.tableCellText}>Additional environments can be provided at £4500/$6000 per environment per year</Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.tableColumnHeadingSmall}>Capacity</TableCell>
                      <TableCell className={classes.tableText}>Configurable. Customer can choose from any number of AWS instance types and can upgrade to larger instances types at a later date if required</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.tableColumnHeadingSmall}>Resilience</TableCell>
                      <TableCell className={classes.tableText}>The licence includes two AWS regions of customer's choice (assuming infrastructure available) giving maximum availability</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.tableColumnHeadingSmall}>Security</TableCell>
                      <TableCell className={classes.tableText}>
                        <Typography paragraph className={classes.tableCellText}>The licence includes two AWS regions of customer's choice (assuming infrastructure available) allowing for customers to have the service located to ensure compliance with security policies</Typography>
                        <Typography className={classes.tableCellText}>The service can, optionally, be hosted under a customer's own AWS account (at additional cost)</Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.tableColumnHeadingSmall}>Service Level</TableCell>
                      <TableCell className={classes.tableText}>Depends upon configuration. With two regions expected level is &gt;99.5%</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.tableColumnHeadingSmall}>Support (Email)</TableCell>
                      <TableCell className={classes.tableText}>
                        <Typography paragraph className={classes.tableCellText}>24x7 Response within 12 hours</Typography>
                        <Typography className={classes.tableCellText}>Please contact us to discuss further options</Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.tableColumnHeadingSmall} style={{ borderBottomLeftRadius: 4 }}>Data</TableCell>
                      <TableCell className={classes.tableText}>Configurable and only limited by customer's choice of infrastructure</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.tableColumnHeadingSmall} style={{ borderBottomLeftRadius: 4 }}>Webhook Integrations</TableCell>
                      <TableCell className={classes.tableText}>Unlimited (each integration incurs a small infrastructure cost per month)</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.tableColumnHeadingSmall} style={{ borderBottomLeftRadius: 4 }}>Maximum Import Size (MB)</TableCell>
                      <TableCell className={classes.tableText}>500</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Grid>
            }
            {serviceType === SUBSCRIPTION &&
              <React.Fragment>
                <Grid item xs={12}>
                  <div style={{ marginBottom: 20 }}>
                    <ButtonSwitch
                      buttonWidth="240px"
                      onSelect={this.selectTimePeriod}
                      options={["Monthly", "Yearly"]}
                      selected={timePeriod === MONTH ? 0 : 1} />
                  </div>
                </Grid>
                <Grid item xs={12}>
                  {!isLarge ?
                    products.map((product, index) =>
                      <Table
                        key={index}
                        data-test="pricing"
                        style={{
                          marginBottom: 20,
                          backgroundColor: '#ffffff',
                          borderRadius: '4px',
                          boxShadow: '0px 6px 12px #00000029'
                        }}>
                        <TableHead>
                          <TableRow>
                            <TableCell className={classes.tableCell}>
                              <Typography className={classes.tableHeading}>{product.name}</Typography>
                              {timePeriod === MONTH &&
                                <React.Fragment>
                                  {product.hasOwnProperty("price") && <Typography variant="h2">£{product.price}</Typography>}
                                  {product.hasOwnProperty("price") && <Typography variant="h2">${product.dollarPrice}</Typography>}
                                  {!product.hasOwnProperty("price") && <Typography variant="h2">Yearly Only</Typography>}
                                </React.Fragment>
                              }
                              {timePeriod === YEAR && product.hasOwnProperty("price") && !product.priceYearly &&
                                <Typography variant="h2">N/A</Typography>
                              }
                              {timePeriod === YEAR && product.priceYearly &&
                                <React.Fragment>
                                  {<Typography variant="h2">£{product.priceYearly}</Typography>}
                                  {<Typography variant="h2">${product.dollarPriceYearly}</Typography>}
                                  {product.hasOwnProperty("price") &&
                                    <div style={{ backgroundColor: gotheme.palette.primary.main, width: 150 }}>
                                      <Typography variant="body1" style={{ color: '#ffffff', padding: '4px 10px' }}>One Month Free*</Typography>
                                    </div>
                                  }
                                </React.Fragment>
                              }
                              {product.hasOwnProperty("price") && product.price !== 0 && <Typography className={classes.yearly} style={{ fontSize: 14 }}>(Excl. of Sales Tax)</Typography>}
                            </TableCell>
                            <TableCell className={classes.tableCellSmall} align="right">
                              <PrimaryButton onClick={() => this.goToGraphologi(product.buttonText)}>{product.buttonText}</PrimaryButton>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell className={classes.tableColumnHeadingSmall}>Description</TableCell>
                            <TableCell className={classes.tableText}>{product.description}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className={classes.tableColumnHeadingSmall}>Taxonomy Management</TableCell>
                            <TableCell className={classes.tableText}>
                              <CheckCircleIcon style={{ color: gotheme.palette.secondary.main }} />
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className={classes.tableColumnHeadingSmall}>Ontology Management</TableCell>
                            <TableCell className={classes.tableText}>
                              <CheckCircleIcon style={{ color: gotheme.palette.secondary.main }} />
                            </TableCell>
                          </TableRow>
                          {timePeriod === MONTH &&
                            <TableRow>
                              <TableCell className={classes.tableColumnHeadingSmall}>Yearly subscriptions only</TableCell>
                              <TableCell className={classes.tableText}>
                                <CheckCircleIcon style={{ color: gotheme.palette.secondary.main }} />
                              </TableCell>
                            </TableRow>
                          }
                          {timePeriod === YEAR &&
                            <TableRow>
                              <TableCell className={classes.tableColumnHeadingSmall}>Data Management</TableCell>
                              <TableCell className={classes.tableText}>
                                <span>Available as Add-On</span>
                              </TableCell>
                            </TableRow>
                          }
                          <TableRow>
                            <TableCell className={classes.tableColumnHeadingSmall}>SKOS-XL</TableCell>
                            <TableCell className={classes.tableText}>
                              <CheckCircleIcon style={{ color: gotheme.palette.secondary.main }} />
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className={classes.tableColumnHeadingSmall}>Collaboration</TableCell>
                            <TableCell className={classes.tableText}>
                              {product.collaboration ?
                                <CheckCircleIcon style={{ color: gotheme.palette.secondary.main }} />
                                :
                                <CancelIcon style={{ color: gotheme.palette.error.main }} />
                              }
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className={classes.tableColumnHeadingSmall}>Reporting</TableCell>
                            <TableCell className={classes.tableText}>
                              {product.reporting ?
                                <CheckCircleIcon style={{ color: gotheme.palette.secondary.main }} />
                                :
                                <CancelIcon style={{ color: gotheme.palette.error.main }} />
                              }
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className={classes.tableColumnHeadingSmall}>Versions</TableCell>
                            <TableCell className={classes.tableText}>
                              {product.versions ?
                                <CheckCircleIcon style={{ color: gotheme.palette.secondary.main }} />
                                :
                                <CancelIcon style={{ color: gotheme.palette.error.main }} />
                              }
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className={classes.tableColumnHeadingSmall}>Bulk Editing</TableCell>
                            <TableCell className={classes.tableText}>
                              {product.bulkEdit ?
                                <CheckCircleIcon style={{ color: gotheme.palette.secondary.main }} />
                                :
                                <CancelIcon style={{ color: gotheme.palette.error.main }} />
                              }
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className={classes.tableColumnHeadingSmall}>Workflow</TableCell>
                            <TableCell className={classes.tableText}>
                              {product.notifications ?
                                <CheckCircleIcon style={{ color: gotheme.palette.secondary.main }} />
                                :
                                <CancelIcon style={{ color: gotheme.palette.error.main }} />
                              }
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className={classes.tableColumnHeadingSmall}>Included Training</TableCell>
                            <TableCell className={classes.tableText}>
                              {product.training !== undefined ?
                                <CheckCircleIcon style={{ color: gotheme.palette.secondary.main }} />
                                :
                                <CancelIcon style={{ color: gotheme.palette.error.main }} />
                              }
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className={classes.tableColumnHeadingSmall}>Discount on Additional Environments</TableCell>
                            <TableCell className={classes.tableText}>
                              {product.discountOnExtraEnvironment ?
                                <CheckCircleIcon style={{ color: gotheme.palette.secondary.main }} />
                                :
                                <CancelIcon style={{ color: gotheme.palette.error.main }} />
                              }
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className={classes.tableColumnHeadingSmall}>Single Sign-on Available</TableCell>
                            <TableCell className={classes.tableText}>
                              {product.sso ?
                                <CheckCircleIcon style={{ color: gotheme.palette.secondary.main }} />
                                :
                                <CancelIcon style={{ color: gotheme.palette.error.main }} />
                              }
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className={classes.tableColumnHeadingSmall}>Custom subdomain</TableCell>
                            <TableCell className={classes.tableText}>
                              {product.customDomain ?
                                <CheckCircleIcon style={{ color: gotheme.palette.secondary.main }} />
                                :
                                <CancelIcon style={{ color: gotheme.palette.error.main }} />
                              }
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className={classes.tableColumnHeading}>Customer's Choice for Deployment Region</TableCell>
                            <TableCell className={classes.tableText}>
                              {product.customDeploymentRegion ?
                                <CheckCircleIcon style={{ color: gotheme.palette.secondary.main }} />
                                :
                                <CancelIcon style={{ color: gotheme.palette.error.main }} />
                              }
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className={classes.tableColumnHeadingSmall}>Custom Terms & Conditions</TableCell>
                            <TableCell className={classes.tableText}>
                              {product.customTerms ?
                                <Badge badgeContent={<span>††</span>}><CheckCircleIcon style={{ color: gotheme.palette.secondary.main }} /></Badge>
                                :
                                <CancelIcon style={{ color: gotheme.palette.error.main }} />
                              }
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className={classes.tableColumnHeadingSmall}>Users</TableCell>
                            <TableCell className={classes.tableText}>{product.maxUsers}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className={classes.tableColumnHeadingSmall}>Infrastructure</TableCell>
                            <TableCell className={classes.tableText}>{product.capacity}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className={classes.tableColumnHeadingSmall}>Resilience</TableCell>
                            <TableCell className={classes.tableText}>{product.resilience}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className={classes.tableColumnHeadingSmall}>Service Level</TableCell>
                            <TableCell className={classes.tableText}>{product.service}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className={classes.tableColumnHeadingSmall}>Support (Email)</TableCell>
                            <TableCell className={classes.tableText}>{product.support}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className={classes.tableColumnHeadingSmall} style={{ borderBottomLeftRadius: 4 }}>Data<sup>†</sup></TableCell>
                            <TableCell className={classes.tableText}>{product.data}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className={classes.tableColumnHeadingSmall} style={{ borderBottomLeftRadius: 4 }}>Webhook Integrations</TableCell>
                            <TableCell className={classes.tableText}>{product.integrations}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className={classes.tableColumnHeadingSmall} style={{ borderBottomLeftRadius: 4 }}>Maximum Import Size (MB)</TableCell>
                            <TableCell className={classes.tableText}>{product.maxImportSize}</TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    )
                    :
                    <Table data-test="pricing" style={{
                      borderColor: '#F2F7FF',
                      backgroundColor: '#ffffff',
                      borderRadius: '4px',
                      boxShadow: '0px 6px 12px #00000029'
                    }}>
                      <TableHead>
                        <TableRow style={{ height: 200 }}>
                          <TableCell className={classes.tableColumnHeading} style={{ borderTopLeftRadius: 4 }}></TableCell>
                          {products.map((product, index) =>
                            <TableCell style={{
                              height: 225,
                              backgroundColor: product.highlight ? "rgb(0, 169, 224, 0.1)" : 'white'
                            }}
                              key={index}
                              className={classes.tableCell}>
                              <div style={{ display: 'flex', height: '100%', flexDirection: 'column', alignItems: 'stretch' }}>
                                <div style={{ flex: 1 }}>
                                  <Typography className={classes.tableHeading}>{product.name}</Typography>
                                  {timePeriod === MONTH &&
                                    <React.Fragment>
                                      {product.hasOwnProperty("price") && <Typography variant="h2">£{product.price}</Typography>}
                                      {product.hasOwnProperty("price") && <Typography variant="h2">${product.dollarPrice}</Typography>}
                                      {!product.hasOwnProperty("price") && <Typography variant="h2">Yearly Only</Typography>}
                                    </React.Fragment>
                                  }
                                  {timePeriod === YEAR && product.hasOwnProperty("price") && !product.priceYearly &&
                                    <Typography variant="h2">N/A</Typography>
                                  }
                                  {timePeriod === YEAR && product.priceYearly &&
                                    <React.Fragment>
                                      {<Typography variant="h2">£{product.priceYearly}</Typography>}
                                      {<Typography variant="h2">${product.dollarPriceYearly}</Typography>}
                                      {product.hasOwnProperty("price") &&
                                        <div style={{ backgroundColor: gotheme.palette.primary.main, width: 150 }}>
                                          <Typography variant="body1" style={{ color: '#ffffff', padding: '4px 10px' }}>One Month Free*</Typography>
                                        </div>
                                      }
                                    </React.Fragment>
                                  }
                                  {(product.hasOwnProperty("price") || (timePeriod === YEAR && product.hasOwnProperty("priceYearly"))) && product.price !== 0 &&
                                    <Typography className={classes.yearly} style={{ fontSize: 14 }}>(Excl. of Sales Tax)</Typography>}
                                </div>
                                <div style={{ flex: 0 }}>
                                  <PrimaryButton onClick={() => this.goToGraphologi(product.buttonText)}>{width === "md" ? product.smallButtonText : product.buttonText}</PrimaryButton>
                                </div>
                              </div>
                            </TableCell>
                          )}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell className={classes.tableColumnHeading}>Description</TableCell>
                          {products.map((product, index) => <TableCell key={index} className={product.highlight ? classes.tableTextHighlight : classes.tableText}>{product.description}</TableCell>)}
                        </TableRow>
                        <TableRow>
                          <TableCell className={classes.tableColumnHeading}>Taxonomy Management</TableCell>
                          {products.map((product, index) =>
                            <TableCell key={index} className={product.highlight ? classes.tableTextHighlight : classes.tableText}>
                              <CheckCircleIcon style={{ color: gotheme.palette.secondary.main }} />
                            </TableCell>
                          )}
                        </TableRow>
                        <TableRow>
                          <TableCell className={classes.tableColumnHeading}>Ontology Management</TableCell>
                          {products.map((product, index) =>
                            <TableCell key={index} className={product.highlight ? classes.tableTextHighlight : classes.tableText}>
                              <CheckCircleIcon style={{ color: gotheme.palette.secondary.main }} />
                            </TableCell>
                          )}
                        </TableRow>
                        {timePeriod === MONTH &&
                          <TableRow>
                            <TableCell className={classes.tableColumnHeading}>Data Management</TableCell>
                            <TableCell className={classes.tableText}>
                              <span>Not available.</span>
                            </TableCell>
                            <TableCell className={classes.tableTextHighlight}>
                              <span>Available yearly only.</span>
                            </TableCell>
                            <TableCell className={classes.tableTextHighlight}>
                              <span>Available yearly only.</span>
                            </TableCell>
                          </TableRow>
                        }
                        {timePeriod === YEAR &&
                          <TableRow>
                            <TableCell className={classes.tableColumnHeading}>Data Management</TableCell>
                            <TableCell className={classes.tableText}>
                              <span>Not available.</span>
                            </TableCell>
                            <TableCell className={classes.tableTextHighlight}>
                              <span>Available as add-on. <a href="#dm-pricing">View Details</a>.</span>
                            </TableCell>
                            <TableCell className={classes.tableTextHighlight}>
                              <span>Available as add-on. <a href="#dm-pricing">View Details</a>.</span>
                            </TableCell>
                          </TableRow>
                        }
                        <TableRow>
                          <TableCell className={classes.tableColumnHeading}>SKOS-XL</TableCell>
                          {products.map((product, index) =>
                            <TableCell key={index} className={product.highlight ? classes.tableTextHighlight : classes.tableText}>
                              <CheckCircleIcon style={{ color: gotheme.palette.secondary.main }} />
                            </TableCell>
                          )}
                        </TableRow>
                        <TableRow>
                          <TableCell className={classes.tableColumnHeading}>Collaboration</TableCell>
                          {products.map((product, index) =>
                            <TableCell key={index} className={product.highlight ? classes.tableTextHighlight : classes.tableText}>
                              {product.collaboration ?
                                <CheckCircleIcon style={{ color: gotheme.palette.secondary.main }} />
                                :
                                <CancelIcon style={{ color: gotheme.palette.error.main }} />
                              }
                            </TableCell>
                          )}
                        </TableRow>
                        <TableRow>
                          <TableCell className={classes.tableColumnHeading}>Reporting</TableCell>
                          {products.map((product, index) =>
                            <TableCell key={index} className={product.highlight ? classes.tableTextHighlight : classes.tableText}>
                              {product.reporting ?
                                <CheckCircleIcon style={{ color: gotheme.palette.secondary.main }} />
                                :
                                <CancelIcon style={{ color: gotheme.palette.error.main }} />
                              }
                            </TableCell>
                          )}
                        </TableRow>
                        <TableRow>
                          <TableCell className={classes.tableColumnHeading}>Versions</TableCell>
                          {products.map((product, index) =>
                            <TableCell key={index} className={product.highlight ? classes.tableTextHighlight : classes.tableText}>
                              {product.versions ?
                                <CheckCircleIcon style={{ color: gotheme.palette.secondary.main }} />
                                :
                                <CancelIcon style={{ color: gotheme.palette.error.main }} />
                              }
                            </TableCell>
                          )}
                        </TableRow>
                        <TableRow>
                          <TableCell className={classes.tableColumnHeading}>Bulk Editing</TableCell>
                          {products.map((product, index) =>
                            <TableCell key={index} className={product.highlight ? classes.tableTextHighlight : classes.tableText}>
                              {product.bulkEdit ?
                                <CheckCircleIcon style={{ color: gotheme.palette.secondary.main }} />
                                :
                                <CancelIcon style={{ color: gotheme.palette.error.main }} />
                              }
                            </TableCell>
                          )}
                        </TableRow>
                        <TableRow>
                          <TableCell className={classes.tableColumnHeading}>Workflow</TableCell>
                          {products.map((product, index) =>
                            <TableCell key={index} className={product.highlight ? classes.tableTextHighlight : classes.tableText}>
                              {product.notifications ?
                                <CheckCircleIcon style={{ color: gotheme.palette.secondary.main }} />
                                :
                                <CancelIcon style={{ color: gotheme.palette.error.main }} />
                              }
                            </TableCell>
                          )}
                        </TableRow>
                        <TableRow>
                          <TableCell className={classes.tableColumnHeading}>Included Training</TableCell>
                          {
                            products.map((product, index) => {
                              return <TableCell key={index} className={product.highlight ? classes.tableTextHighlight : classes.tableText}>
                                {product.training
                                  ? <CheckCircleIcon style={{ color: gotheme.palette.secondary.main }} /> : <CancelIcon style={{ color: gotheme.palette.error.main }} />
                                }</TableCell>;
                            })
                          }
                        </TableRow>
                        <TableRow>
                          <TableCell className={classes.tableColumnHeading}>Discount On Additional Environments</TableCell>
                          {products.map((product, index) =>
                            <TableCell key={index} className={product.highlight ? classes.tableTextHighlight : classes.tableText}>
                              {product.discountOnExtraEnvironment ?
                                <CheckCircleIcon style={{ color: gotheme.palette.secondary.main }} />
                                :
                                <CancelIcon style={{ color: gotheme.palette.error.main }} />
                              }
                            </TableCell>
                          )}
                        </TableRow>
                        <TableRow>
                          <TableCell className={classes.tableColumnHeading}>Single Sign-on Available</TableCell>
                          {products.map((product, index) => <TableCell key={index} className={product.highlight ? classes.tableTextHighlight : classes.tableText}>
                            {product.sso ?
                              <CheckCircleIcon style={{ color: gotheme.palette.secondary.main }} />
                              :
                              <CancelIcon style={{ color: gotheme.palette.error.main }} />
                            }
                          </TableCell>)}
                        </TableRow>
                        <TableRow>
                          <TableCell className={classes.tableColumnHeading}>Custom Subdomain</TableCell>
                          {products.map((product, index) => <TableCell key={index} className={product.highlight ? classes.tableTextHighlight : classes.tableText}>
                            {product.customDomain ?
                              <CheckCircleIcon style={{ color: gotheme.palette.secondary.main }} />
                              :
                              <CancelIcon style={{ color: gotheme.palette.error.main }} />
                            }
                          </TableCell>)}
                        </TableRow>
                        <TableRow>
                          <TableCell className={classes.tableColumnHeading}>Customer's Choice for Deployment Region</TableCell>
                          {products.map((product, index) => <TableCell key={index} className={product.highlight ? classes.tableTextHighlight : classes.tableText}>
                            {product.customDeploymentRegion ?
                              <CheckCircleIcon style={{ color: gotheme.palette.secondary.main }} />
                              :
                              <CancelIcon style={{ color: gotheme.palette.error.main }} />
                            }
                          </TableCell>)}
                        </TableRow>
                        <TableRow>
                          <TableCell className={classes.tableColumnHeading}>Custom Terms & Conditions</TableCell>
                          {products.map((product, index) =>
                            <TableCell key={index} className={product.highlight ? classes.tableTextHighlight : classes.tableText}>
                              {product.customTerms ?
                                <Badge badgeContent={<span>††</span>}><CheckCircleIcon style={{ color: gotheme.palette.secondary.main }} /></Badge>
                                :
                                <CancelIcon style={{ color: gotheme.palette.error.main }} />
                              }
                            </TableCell>
                          )}
                        </TableRow>
                        <TableRow>
                          <TableCell className={classes.tableColumnHeading}>Users</TableCell>
                          {products.map((product, index) => <TableCell key={index} className={product.highlight ? classes.tableTextHighlight : classes.tableText}>{product.maxUsers}</TableCell>)}
                        </TableRow>
                        <TableRow>
                          <TableCell className={classes.tableColumnHeading}>Infrastructure</TableCell>
                          {products.map((product, index) => <TableCell key={index} className={product.highlight ? classes.tableTextHighlight : classes.tableText}>{product.capacity}</TableCell>)}
                        </TableRow>
                        <TableRow>
                          <TableCell className={classes.tableColumnHeading}>Resilience</TableCell>
                          {products.map((product, index) => <TableCell key={index} className={product.highlight ? classes.tableTextHighlight : classes.tableText}>{product.resilience}</TableCell>)}
                        </TableRow>
                        <TableRow>
                          <TableCell className={classes.tableColumnHeading}>Service Level</TableCell>
                          {products.map((product, index) => <TableCell key={index} className={product.highlight ? classes.tableTextHighlight : classes.tableText}>{product.service}</TableCell>)}
                        </TableRow>
                        <TableRow>
                          <TableCell className={classes.tableColumnHeading}>Support (Email)</TableCell>
                          {products.map((product, index) => <TableCell key={index} className={product.highlight ? classes.tableTextHighlight : classes.tableText}>{product.support}</TableCell>)}
                        </TableRow>
                        <TableRow>
                          <TableCell className={classes.tableColumnHeading} style={{ borderBottomLeftRadius: 4 }}>Data<sup>†</sup></TableCell>
                          {products.map((product, index) => <TableCell key={index} className={product.highlight ? classes.tableTextHighlight : classes.tableText}>{product.data}</TableCell>)}
                        </TableRow>
                        <TableRow>
                          <TableCell className={classes.tableColumnHeading} style={{ borderBottomLeftRadius: 4 }}>Webhook Integrations</TableCell>
                          {products.map((product, index) => <TableCell key={index} className={product.highlight ? classes.tableTextHighlight : classes.tableText}>{product.integrations}</TableCell>)}
                        </TableRow>
                        <TableRow>
                          <TableCell className={classes.tableColumnHeading} style={{ borderBottomLeftRadius: 4 }}>Maximum Import Size (MB)</TableCell>
                          {products.map((product, index) => <TableCell key={index} className={product.highlight ? classes.tableTextHighlight : classes.tableText}>{product.maxImportSize}</TableCell>)}
                        </TableRow>
                      </TableBody>
                    </Table>
                  }
                  <Typography style={{ marginTop: 20 }}>* One month free on all yearly subscriptions</Typography>
                  <Typography>** UK business hours</Typography>
                  <Typography>† Soft limits apply. Warnings on breach of limits will be shown and you should contact us if this happens.</Typography>
                  <Typography>†† Customisable at additional cost.</Typography>
                  <Typography>Prices are exclusive of sales tax.</Typography>
                </Grid>
              </React.Fragment>
            }
          </Grid>
          {timePeriod === YEAR &&
            <div>
              <Table
                id="dm-pricing"
                data-test="dm-pricing"
                style={{
                  marginBottom: 20,
                  marginTop: 60,
                  backgroundColor: '#ffffff',
                  borderRadius: '4px',
                  boxShadow: '0px 6px 12px #00000029'
                }}>
                <TableHead>
                  <TableRow>
                    <TableCell colSpan="2" className={classes.tableCell}>
                      <Typography className={classes.tableHeading}>Data Management</Typography>
                      <React.Fragment>
                        <Typography variant="h2">£8000 <span style={{ fontSize: 20 }}>per year</span></Typography>
                        <Typography variant="h2">$11200 <span style={{ fontSize: 20 }}>per year</span></Typography>
                      </React.Fragment>
                      <Typography paragraph className={classes.yearly} style={{ fontSize: 14 }}>(Excl. of Sales Tax)</Typography>
                      <div style={{ flex: 0 }}>
                        <PrimaryButton onClick={() => this.goToGraphologi('Contact')}>Contact</PrimaryButton>
                      </div>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell className={classes.tableColumnHeadingSmall}>Number of datasets</TableCell>
                    <TableCell className={classes.tableText}>Unlimited</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.tableColumnHeadingSmall}>Number of resources per dataset<sup>†</sup></TableCell>
                    <TableCell className={classes.tableText}>100,000</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.tableColumnHeadingSmall}>API writes<sup>†</sup></TableCell>
                    <TableCell className={classes.tableText}>Maximum 1 million per month</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <Typography>† Soft limits apply.</Typography>
              <Typography>Prices are exclusive of sales tax.</Typography>
            </div>
          }
        </PageRow>
        {serviceType === SUBSCRIPTION &&
          <PageRow spaceTope="140px">
            <PricingFAQ />
          </PageRow>
        }
      </div>
    );
  }
}

Pricing.propTypes = {
  goTo: PropTypes.func.isRequired,
}

export default withRouter(withWidth()(withStyles(styles)(Pricing)));