import { NavLink } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import React, { Component } from 'react';
import { globalStyles } from '../style/gotheme';
import PageRow from './PageRow';
import releaseNotes from './releases.json'
import DescriptionIcon from '@material-ui/icons/Description';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';

const styles = theme => {
  return Object.assign({
    prevReleaseLink: {
      color: '#0066CC'
    },
    releaseJumpLink: {
      color: '#0066CC',
      fontWeight: 500
    },
    releaseBlock: {
      paddingBottom: 24,
      borderBottom: 'solid 1px #00A9E04D',
      marginBottom: 24
    }
  }, globalStyles)
};

class ReleaseNotes extends Component {

  render() {

    const { classes, width } = this.props;
    const isLarge = width === "md" || width === "lg" || width === "xl";
    const version = Object.keys(releaseNotes)[0];
    const data = releaseNotes[version];

    return (
      <div>
        {isLarge &&
          <div style={{ position: 'absolute', overflow: 'hidden', width: '100%', right: 0 }}>
            <img
              src="/images/GraphologiIcon.svg"
              style={{
                float: 'right',
                height: 764,
                marginTop: '-100px',
                marginRight: -160
              }}
              alt="" />
          </div>
        }
        <PageRow spaceTop={isLarge ? "100px" : "36px"} spaceBottom={isLarge ? "50px" : "0px"} color={isLarge ? null : "#F2F7FF"}>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h1" style={{ marginBottom: isLarge ? "100px" : "0px" }}>Release Notes</Typography>
            </Grid>
          </Grid>
        </PageRow>
        <PageRow spaceTop={isLarge ? "70px" : "0px"} color="#F2F7FF">
          <Grid container>
            <Grid item xs={12} md={8}>
              <div style={{ paddingRight: 120 }}>
                <div className={classes.releaseBlock}>
                  <Typography variant="h2">What's New</Typography>
                  <Typography variant="h2">{data.title}</Typography>
                </div>
                <div className={classes.releaseBlock}>
                  <Typography paragraph variant="h2">Enhancements</Typography>
                  {data.enhancements.map((enh, i) =>
                    <div style={{ marginBottom: 24 }}>
                      <Typography key={i} variant="body1">{enh}</Typography>
                      <div style={{ marginTop: 4 }}>
                        {data.links && data.links[i] && data.links[i] !== "" &&
                          <a href={data.links[i]} style={{marginRight: 20}}>
                            <span className={classes.releaseJumpLink}>
                              <DescriptionIcon style={{ verticalAlign: 'middle', marginRight: 4 }} />Documentation</span>
                          </a>
                        }
                        {data.videos && data.videos[i] && data.videos[i] !== "" &&
                          <a href={data.videos[i]}>
                            <span className={classes.releaseJumpLink}>
                              <VideoLibraryIcon style={{ verticalAlign: 'middle', marginRight: 4 }} />Video</span>
                          </a>
                        }
                      </div>
                    </div>
                  )}
                </div>
                {data.fixes &&
                  <div className={classes.releaseBlock}>
                    <Typography paragraph variant="h2">Fixes</Typography>
                    {data.fixes.map((fix, i) => <Typography key={i} paragraph variant="body1">{fix}</Typography>)}
                  </div>
                }
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography paragraph variant="h2">Previous Releases</Typography>
              {Object.keys(releaseNotes).map((rn) =>
                rn !== version &&
                <NavLink key={rn} to={"/release/" + rn}>
                  <Typography variant="body1" className={classes.prevReleaseLink}>{releaseNotes[rn].title}</Typography>
                </NavLink>
              )}
            </Grid>
          </Grid>
        </PageRow>
      </div>
    );
  }
}

export default withWidth()(withStyles(styles)(ReleaseNotes));