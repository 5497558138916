import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { globalStyles, gotheme } from '../style/gotheme';

const styles = theme => {
  return Object.assign({
    featureCard: {
      width: 200,
      minHeight: 260,
      border: 'solid 1px rgba(218, 240, 255, 1)',
      backgroundColor: 'white',
      padding: 20,
      marginBottom: 20,
      borderRadius: 24,
      margin: '0px 5px'
    },
    featureCardTitle: {
      fontFamily: 'roboto',
      fontSize: '20px',
      marginBottom: 16,
      fontWeight: '500',
      lineHeight: '26px',
      textAlign: 'left'
    },
    featureCardIconContainer: {
      width: 64,
      height: 64,
      borderRadius: 96,
      textAlign: 'center',
      margin: '0px auto 20px auto',
      backgroundColor: 'rgba(246, 246, 246, 1)'
    }
  }, globalStyles)
};

class FeatureCard extends Component {

  render() {

    const { classes, icon, title, description } = this.props;

    return (
      <div className={classes.featureCard}>
        <div>
          <div className={classes.featureCardIconContainer}>
            <img src={"images/" + icon} style={{marginTop: 14}} />
          </div>
        </div>
        <Typography className={classes.featureCardTitle}>
          <CheckCircleIcon fontSize="default" style={{
            color: gotheme.palette.secondary.main,
            marginRight: 6,
            verticalAlign: 'top'
          }} />
          {title}
        </Typography>
        <Typography variant="body1" style={{color: '#808080'}}>{description}</Typography>
      </div>
    );
  }
}

FeatureCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired
}

export default withStyles(styles)(FeatureCard);