import { Typography } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import React, { Component } from 'react';
import { globalStyles } from '../style/gotheme';
import PageRow from './PageRow';
import PrimaryButton from './PrimaryButton';
import fetch from 'isomorphic-fetch'

const EMAIL_ENDPOINT = "https://a8yn775uvi.execute-api.eu-west-1.amazonaws.com/prod/contactform";

const styles = theme => {
  return Object.assign({
    formInput: {
      backgroundColor: '#ffffff',
      fontFamily: 'Roboto',
      fontSize: '16px',
      borderRadius: 12,
      '& fieldset': {
        border: 'none',
      }
    },
    formInputFilled: {
      backgroundColor: '#ffffff',
      fontFamily: 'Roboto',
      fontSize: '16px',
      borderRadius: 12,
      '& fieldset': {
        border: 'solid 1px #13294B',
      }
    },
    formInputError: {
      backgroundColor: '#ffffff',
      fontFamily: 'Roboto',
      fontSize: '16px',
      borderRadius: 12,
      '& fieldset': {
        border: 'solid 1px #DD2C1A',
      }
    }
  }, globalStyles)
};

class Contact extends Component {

  constructor(props) {
    super(props);
    this.state = {
      givenName: "",
      familyName: "",
      email: "",
      question: "",
      submitted: false,
      isSent: null
    };
  }

  submitDetails = () => {

    this.setState({ submitted: true }, () => {
      const { givenName, familyName, email, question } = this.state;

      const requestOptions = {
        method: 'POST',
        body: JSON.stringify({
          givenName,
          familyName,
          email,
          question
        }),
        headers: {
          'Content-Type': 'application/json',
        }
      }

      fetch(EMAIL_ENDPOINT, requestOptions)
        .then(response => {
          if (response.status >= 400) {
            this.setState({ isSent: false });
          } else {
            this.setState({ isSent: true });
          }
        })
        .catch(e => {
          this.setState({
            isSent: false
          });
        });
    })
  }

  isValidEmail = (email) => {
    return (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/.test(email));
  }

  onChange = (event) => {
    const value = event.target.value;
    const name = event.target.name;
    this.setState({ [name]: value, submitted: false, isSent: null });
  }

  render() {

    const { classes, width } = this.props;
    const { givenName, familyName, email, question, submitted, isSent } = this.state;
    const isValidEmail = this.isValidEmail(email)
    const isValid = givenName !== "" && familyName !== "" && isValidEmail;
    const isLarge = width === "md" || width === "lg" || width === "xl";

    return (
      <div>
        {isLarge &&
          <div style={{ position: 'absolute', overflow: 'hidden', width: '100%', right: 0 }}>
            <img
              src="/images/GraphologiIcon.svg"
              style={{
                float: 'right',
                height: 764,
                marginTop: '-100px',
                marginRight: -160
              }}
              alt="" />
          </div>
        }
        <PageRow spaceTop={isLarge ? "100px" : "36px"} spaceBottom={isLarge ? "50px" : "0px"} color={isLarge ? null : "#F2F7FF"}>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h1" style={{ marginBottom: isLarge ? "100px" : "0px" }}>Contact</Typography>
            </Grid>
          </Grid>
        </PageRow>
        <PageRow spaceTop={isLarge ? "70px" : "0px"} color="#F2F7FF">
          <Grid container>
            <Grid item xs={12} md={8}>
              <div style={{ marginBottom: 20 }}>
                <FormControl component="fieldset" style={{ width: '100%', maxWidth: '732px' }}>
                  <FormLabel component="p" className={classes.standardLargeText}>Given Name*</FormLabel>
                  <OutlinedInput
                    inputProps={{
                      maxLength: 50
                    }}
                    name="givenName"
                    autoFocus
                    value={givenName}
                    onChange={this.onChange}
                    className={givenName.length > 0 ? classes.formInputFilled : classes.formInput}
                    placeholder="Your given name" />
                </FormControl>
                <FormControl component="fieldset" style={{ width: '100%', maxWidth: '732px' }}>
                  <FormLabel component="p" className={classes.standardLargeText}>Family Name*</FormLabel>
                  <OutlinedInput
                    inputProps={{
                      maxLength: 50
                    }}
                    name="familyName"
                    value={familyName}
                    onChange={this.onChange}
                    className={familyName.length > 0 ? classes.formInputFilled : classes.formInput}
                    placeholder="Your family name" />
                </FormControl>
                <FormControl component="fieldset" style={{ width: '100%', maxWidth: '732px' }}>
                  <FormLabel component="p" className={classes.standardLargeText}>Email*</FormLabel>
                  <OutlinedInput
                    inputProps={{
                      maxLength: 100
                    }}
                    error={email.length > 0 && !isValidEmail}
                    name="email"
                    value={email}
                    onChange={this.onChange}
                    className={email.length > 0 && !isValidEmail ?
                      classes.formInputError
                      :
                      isValidEmail ?
                        classes.formInputFilled
                        :
                        classes.formInput
                    }
                    placeholder="Your email address" />
                </FormControl>
                <FormControl component="fieldset" style={{ width: '100%', maxWidth: '732px' }}>
                  <FormLabel component="p" className={classes.standardLargeText}>Questions</FormLabel>
                  <OutlinedInput
                    inputProps={{
                      maxLength: 500
                    }}
                    rows={10}
                    rowsMax={10}
                    multiline
                    name="question"
                    value={question}
                    onChange={this.onChange}
                    className={classes.formInput}
                    placeholder="What would you like to ask us?" />
                </FormControl>
              </div>
              <PrimaryButton disabled={!isValid || submitted} onClick={this.submitDetails}>Send</PrimaryButton>
              {submitted && isSent !== null &&
                <Typography variant="body1" style={{ marginTop: 20 }}>
                  {isSent ?
                    "Thank you. Your information has been sent."
                    :
                    "We are sorry. For some reason we cannot send your details at the momemt."
                  }
                </Typography>
              }
            </Grid>
            <Grid item xs={12} md={4}>
              <div style={!isLarge ?
                { marginTop: "60px" }
                :
                { marginTop: "60px", marginLeft: width === "md" ? 60 : 132 }
              }>
                <Typography variant="body1" paragraph>
                  Graphifi Ltd<br />
                The Enterprise Centre<br />
                University of East Anglia<br />
                Norwich Research Park<br />
                Norwich<br />
                NR4 7TJ</Typography>
                <Typography variant="body1"><a href="mailto:info@graphifi.com">info@graphifi.com</a></Typography>
              </div>
            </Grid>
          </Grid>
        </PageRow>
      </div>
    );
  }
}

export default withWidth()(withStyles(styles)(Contact));