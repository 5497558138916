import { Typography } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { globalStyles, gotheme } from '../style/gotheme';

const styles = theme => {
  return Object.assign({  
    featureText: {
      fontFamily: 'Roboto',
      fontSize: 20,
      textAlign: 'left',
      fontWeight: 400
    },
    featureListItem: {
      paddingTop: 0,
      paddingBottom: 0
    },
    featureListText: {
      fontFamily: 'Roboto',
      fontWeight: 400,
      lineHeight: '26px',
      fontSize: 18,
      marginLeft: 12,
      paddingTop: 3
    },
    listItemAvatar: {
      marginTop: '0px',
    }
  }, globalStyles)
};

class VerticalList extends Component {

  render() {

    const { classes, items, description } = this.props;

    return (
      <div>
        <Typography className={classes.featureText}>{description}</Typography>
        <div>
          <List>
            {items.map((item, index) =>
              <ListItem
                classes={{
                  alignItemsFlexStart: classes.listItemAvatar
                }}
                key={index}
                alignItems="flex-start"
                disableGutters
                className={classes.featureListItem}>
                <ListItemAvatar>
                  <CheckCircleIcon style={{ color: gotheme.palette.secondary.main }} />
                </ListItemAvatar>
                <ListItemText disableTypography className={classes.featureListText} primary={item} />
              </ListItem>
            )}
          </List>
        </div>
      </div>
    );
  }
}

VerticalList.propTypes = {
  description: PropTypes.string,
  items: PropTypes.array.isRequired
}

export default withStyles(styles)(VerticalList);