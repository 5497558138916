import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import React, { Component } from 'react';
import { globalStyles } from '../style/gotheme';
import PageRow from './PageRow';

const styles = theme => {
  return Object.assign({
  }, globalStyles)
};

class Testimonials extends Component {

  render() {

    const { classes, width } = this.props;
    const isLarge = width === "md" || width === "lg" || width === "xl";

    return (
      <div>
        {isLarge &&
          <div style={{ position: 'absolute', overflow: 'hidden', width: '100%', right: 0 }}>
            <img
              src="/images/GraphologiIcon.svg"
              style={{
                float: 'right',
                height: 764,
                marginTop: '-100px',
                marginRight: -160
              }}
              alt="" />
          </div>
        }
        <PageRow spaceTop={isLarge ? "100px" : "36px"} spaceBottom={isLarge ? "50px" : "0px"} color={isLarge ? null : "#F2F7FF"}>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h1" style={{ marginBottom: isLarge ? "100px" : "0px" }}>Testimonials</Typography>
            </Grid>
          </Grid>
        </PageRow>
        <PageRow spaceTop={isLarge ? "70px" : "0px"} color="#F2F7FF">
          <div style={{ maxWidth: '624px', margin: '0 auto' }}>
          </div>
        </PageRow>
      </div>
    );
  }
}

export default withWidth()(withStyles(styles)(Testimonials));