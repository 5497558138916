import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import { globalStyles as styles } from '../style/gotheme';

class OrganisingSubsetsOfInformation extends Component {

  render() {

    const { classes } = this.props;

    return (
      <div>
        <Typography paragraph variant="body1">One of the questions we get asked quite frequently about Graphologi is how best to use it to organise subsets of information. SKOS is all about knowledge organisation, but not everybody is familiar with <a className={classes.wpLink} href="https://www.w3.org/TR/skos-reference/" target="_new">SKOS</a> or the various options it offers and with the support for ontologies in Graphologi even more options become possible.</Typography>
        <Typography paragraph variant="body1">One feature of SKOS that many users are unfamiliar with is collections. The SKOS standard defines two types of <a className={classes.wpLink} href="https://www.w3.org/TR/skos-primer/#seccollections" target="_new">collection</a> - ordered and unordered. Technically, ordered collections are also unordered collections. Furthermore, collections can contain other collections (but we have never seen a real-world use case for this yet so currently do not support it).</Typography>
        <Typography paragraph variant="body1">Generally a taxonomy (SKOS concept scheme) will contain a (possibly hierarchical) set of concepts organised for a particular purpose. However, sometimes it is useful to create subsets of taxonomies for various purposes.</Typography>
        <Typography paragraph variant="body1">For example, let’s say we have a taxonomy of countries. What are our options to create subsets of those countries that are continental lists?</Typography>
        <Typography paragraph variant="body1">One option is to create more taxonomies. In SKOS a concept can be in multiple taxonomies (concept schemes) at once and this can be a powerful feature and one that we would probably say is underused (we have seen many instances of monolithic taxonomies that would be easier to manage if split into multiple taxonomies). So you might have a taxonomy of ‘European Countries’ for instance and further taxonomies for the other continents.</Typography>
        <Typography paragraph variant="body1">Another option is to create a SKOS unordered collection for each continent. For example the screenshot below shows a sample of a Europe collection.</Typography>
        <img alt="Unordered collection" className={classes.wpImage}  src="/images/1656091667333.png" />
        <Typography paragraph variant="body1">One advantage of using collections is potentially a cleaner separation of purpose. Having just one taxonomy to manage all countries is simpler than managing multiple taxonomies and it is simple to add or remove concepts from collections in Graphologi.</Typography>
        <Typography paragraph variant="body1">It might make even more sense to use collections if there are more groups you want to maintain. For instance, there might be a collection of Commonwealth countries or a collection of countries that are in the European Union.</Typography>
        <Typography paragraph variant="body1">The country example is really a flat list, but where the power of collections really comes into play is when the collection contains concepts from hierarchical taxonomies. Adding concepts from any location in a hierarchy to a collection is just as simple to do as from a flat list.</Typography>
        <Typography paragraph variant="body1">Saying that, to some degree, with the trend to use ontologies to enrich taxonomies there is potentially a different and better way to solve the problem. For instance, we may have a taxonomy of countries and a taxonomy of continents and, if our ontology contains a property ‘isLocatedIn’, we can use that on each country concept to connect it to a continent concept.</Typography>
        <img alt="With class" className={classes.wpImage}  src="/images/1656092353280.png" />
        <Typography paragraph variant="body1">We can also go further and assign a Country class or a Continent class from an ontology as well to more explicitly define what our concepts are.</Typography>
        <Typography paragraph variant="body1">This approach gives explicit meaning (semantics) that are not achievable through either the use of collections or the standard SKOS properties (where the best we could probably do is to say they are a related match).</Typography>
        <Typography paragraph variant="body1">Additionally, for certain types of concepts it may also be applicable to use a class hierarchy to create subsets of things. For instance, we may have a class RacingCar as a subclass of Car and it makes more sense in this instance to use the RacingCar class than to create a racing car collection or a racing car taxonomy.</Typography>
        <Typography paragraph variant="body1">The explicit semantics offered by these approaches can also give benefits downstream when it comes to asking questions about the data. It can make it easier to be more accurate.</Typography>
        <Typography paragraph variant="body1">SKOS ordered collections can be very useful for user interfaces. For instance, many interfaces will order concepts by their labels, but this is not necessarily the ideal order in terms of user experience. A collection gives an information architect the opportunity to define a list in the best order for users.</Typography>
        <Typography paragraph variant="body1">For example, take the following example from the BMW website. The labels for the types of car do not follow any order that you could easily compute, but it could make sense to maintain the information on the models in a taxonomy (or taxonomies) as part of a knowledge graph.</Typography>
        <img alt="BMW website" className={classes.wpImage} style={{ width: 600 }} src="/images/1656091481677.png" />
        <Typography paragraph variant="body1">So, whilst there are potentially various options for unordered subsets of things, ordered subsets are definitely a situation where you should consider using SKOS ordered collections.</Typography>
        <Typography paragraph variant="body1">Overall, our answer to the question on how to tackle subsets is generally ‘it depends’. That might be considered a cop out but the correct approach will depend upon the level of knowledge of the user and their level of comfort with each approach. Furthermore, the level of sophistication of downstream systems to take advantage of information will also play an important part in any decision. There is little point in creating a sophisticated graph of knowledge only to find that the downstream systems are just importing flat lists of labels and that this situation will never change.</Typography>
        <Typography paragraph variant="body1">Graphologi supports all of the options mentioned in this article, so in the end it comes down to the choice of the user.</Typography>
      </div>
    );
  }
}

export default withStyles(styles)(OrganisingSubsetsOfInformation);