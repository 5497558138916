import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { globalStyles, gotheme } from '../style/gotheme';

const styles = () => {
  return Object.assign({
    featureText: {
      fontFamily: 'Roboto',
      fontWeight: 400
    },
    featureListItem: {
      paddingTop: 0,
      paddingBottom: 0
    },
    featureListText: {
      fontFamily: 'Roboto',
      fontWeight: 400,
      lineHeight: '26px',
      paddingTop: 3
    },
    listItemAvatar: {
      marginTop: '0px',
    }
  }, globalStyles)
};

class MultiColumnFeatureList extends Component {

  render() {

    const { classes, items, image, title, description } = this.props;
    const columns = [
      {
        start: 0,
        end: Math.floor(items.length / 3)
      },
      {
        start: Math.floor(items.length / 3) + 1,
        end: (Math.floor(items.length / 3)) * 2
      },
      {
        start: (Math.floor(items.length / 3)) * 2 + 1,
        end: items.length - 1
      }
    ]

    return (
      <div style={{ margin: '0px 5px 0px 5px', textAlign: 'center', height: '100%' }}>
        <div style={{ padding: 12, border: 'solid 1px #e0e0e0', borderRadius: 24, marginTop: 20, height: 'calc(100% - 20px)' }}>
          <img style={{ marginTop: 12, height: 110, marginBottom: 18 }} src={"/images/" + image} alt="" />
          <Typography variant="h2" align="center">{title}</Typography>
          <div style={{ minHeight: 50 }}>
            <Typography className={classes.featureText}>{description}</Typography>
          </div>
          <Grid container>
            {columns.map((c, cIndex) => {
              const cItems = items.slice(columns[cIndex].start, columns[cIndex].end);
              return <Grid
                key={cIndex}
                item
                xs={12}
                sm={4}>
                <List>
                  {cItems.map((item, index) =>
                    <ListItem
                      classes={{
                        alignItemsFlexStart: classes.listItemAvatar
                      }}
                      key={index}
                      alignItems="flex-start"
                      disableGutters
                      className={classes.featureListItem}>
                      <ListItemAvatar>
                        <CheckCircleIcon style={{ color: gotheme.palette.secondary.main }} />
                      </ListItemAvatar>
                      <ListItemText disableTypography className={classes.featureListText} primary={item} />
                    </ListItem>
                  )}
                </List>
              </Grid>
            })}
          </Grid>
        </div>
      </div>
    );
  }
}

MultiColumnFeatureList.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired
}

export default withStyles(styles)(MultiColumnFeatureList);