import { Typography } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { globalStyles, gotheme } from '../style/gotheme';

const styles = theme => {
  return Object.assign({
    featureHeadingText: {
      fontFamily: 'Roboto',
      fontSize: 24,
      margin: 0,
      color: '#13294B',
      height: 40,
      textAlign: 'left',
      fontWeight: 700
    },
    featureText: {
      fontFamily: 'Roboto',
      fontSize: 18,
      color: '#13294B',
      textAlign: 'left',
      height: 80,
      fontWeight: 400
    },
    featureListItem: {
      paddingTop: 0,
      paddingBottom: 0
    },
    featureListText: {
      fontFamily: 'Roboto',
      fontWeight: 400,
      lineHeight: '24px',
      color: '#13294B',
      fontSize: 16,
      marginLeft: 12,
      paddingTop: 3
    },
    listItemAvatar: {
      marginTop: '0px',
    }
  }, globalStyles)
};

class VerticalFeatureList extends Component {

  render() {

    const { classes, items, image, title, description } = this.props;

    return (
      <div style={{
        textAlign: 'center',
        margin: '10px 10px 20px 10px',
        zIndex: 10,
        width: 400,
        height: '100%',
        borderRadius: 40,
        backgroundColor: 'white'
      }}>
        <div style={{
          borderTopLeftRadius: 40,
          height: 200,
          width: 400,
          borderTopRightRadius: 40,
          backgroundImage: 'url("/images/' + image + '")',
          backgroundSize: 'cover'
        }}>
        </div>
        <div style={{
          padding: 24,
          borderBottomLeftRadius: 40,
          borderBottomRightRadius: 40,
          background: 'white'
        }}>
          <Typography variant="h2" className={classes.featureHeadingText}>{title}</Typography>
          <div style={{
            width: 60,
            marginTop: 12,
            marginBottom: 12,
            borderBottom: '4px solid rgba(151, 215, 0, 1)'
          }}></div>
          <Typography className={classes.featureText}>{description}</Typography>
          <div style={{height: 260}}>
          <List>
            {items.map((item, index) =>
              <ListItem
                classes={{
                  alignItemsFlexStart: classes.listItemAvatar
                }}
                key={index}
                alignItems="flex-start"
                disableGutters
                className={classes.featureListItem}>
                <ListItemAvatar>
                  <CheckCircleIcon style={{ color: gotheme.palette.secondary.main }} />
                </ListItemAvatar>
                <ListItemText disableTypography className={classes.featureListText} primary={item} />
              </ListItem>
            )}
          </List>
          </div>
        </div>
      </div>
    );
  }
}

VerticalFeatureList.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired
}

export default withStyles(styles)(VerticalFeatureList);