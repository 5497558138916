import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import HelpIcon from '@material-ui/icons/Help';
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { globalStyles as styles } from '../style/gotheme';

class SkosTutorialLink extends Component {

  render() {

    const { classes } = this.props;

    return (
      <div>
        <div className={classes.wpDocLink} style={{ display: 'flex' }}>
          <div style={{ marginRight: 12 }}>
            <HelpIcon fontSize='large' style={{ color: '#97d700' }} />
          </div>
          <div>
            <Typography variant="body1">If you haven't heard of SKOS take a look at this tutorial:<br /><NavLink to="/getting-started-with-standards" className={classes.wpLink}>Getting Started with Standards In Graphologi</NavLink></Typography>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(SkosTutorialLink);