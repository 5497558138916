import { withRouter } from 'react-router-dom';
import withWidth from '@material-ui/core/withWidth';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { globalStyles } from '../style/gotheme';

const styles = theme => {
  return Object.assign({
  }, globalStyles)
};

class Testimonial extends Component {

  goToGraphologi = (page) => {
    if (page === "Testimonial") {
      this.props.history.push("testimonial")
    }
  }

  render() {

    const { classes, image, name, role, words, width, direction, border } = this.props;

    return (
      <div style={{ marginLeft: 'auto', marginRight: 'auto', maxWidth: 1000, marginBottom: 60, paddingBottom: 60, ...(border) && { borderBottom: 'solid 5px white' } }}>
        <Grid container direction={direction}>
          <Grid item xs={12} sm={4} md={3}>
            <div style={{ textAlign: 'center', marginTop: width !== "xs" && width !== "sm" ? 10 : 0 }}>
              <img style={{ width: '80%', maxWidth: width !== "xs" && width !== "sm" ? 300 : 200 }} src={"/images/" + image} alt="" />
              <div>
                <Typography variant="caption">{name}</Typography>
              </div>
              <div>
                <Typography variant="caption">{role}</Typography>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={8} md={9}>
            <div style={{ paddingLeft: 30 }}>
              <div style={width !== "xs" && width !== "sm" ? { marginTop: 20 } : { marginTop: 20 }}>
                <Typography className={classes.words}>
                  {
                    words.map((sentence, index) => {
                      return <span key={index} style={{ display: 'block', marginBottom: 20 }}>
                        {index === 0 && <span>"</span>}
                        {sentence}
                        {index === words.length - 1 && <span>"</span>}
                      </span>
                    })
                  }
                </Typography>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

Testimonial.defaultProps = {
  direction: "row",
  border: false
};

Testimonial.propTypes = {
  direction: PropTypes.string.isRequired,
  border: PropTypes.bool,
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  words: PropTypes.array.isRequired,
}

export default withRouter(withWidth()(withStyles(styles)(Testimonial)));