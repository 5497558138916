import withWidth from '@material-ui/core/withWidth';
import React from 'react';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { globalStyles as styles } from '../style/gotheme';
import PropTypes from 'prop-types';

class ButtonSwitch extends React.Component {

  render() {

    const { classes, onSelect, options, selected, buttonWidth, backgroundColor, width } = this.props;

    return (
      <div style={{
        backgroundColor: '#ffffff',
        height: width === "xs" || width === "sm" ? "auto" : 46,
        borderRadius: 26,
        padding: 12,
        width: width === "xs" || width === "sm" ? "auto": buttonWidth,
        boxShadow: '0px 3px 6px #00000039',
        margin: '0 auto'
      }}>
        {options.map((option, index) => {
          return <Button
            key={index}
            variant="contained"
            style={{
              boxShadow: 'none',
              width: width === "xs" || width === "sm" ? "100%" : "auto",
              marginBottom: (width === "xs" || width === "sm") && index !== options.length  -1 ? "12px" : "0px",
              textAlign: "center",
              display: width === "xs" || width === "sm" ? "block" : "inline",
              borderTopRightRadius: width === "xs" || width === "sm" || index === options.length - 1 ? 16 : 0,
              borderBottomRightRadius: width === "xs" || width === "sm" || index === options.length - 1 ? 16 : 0,
              borderTopLeftRadius: width === "xs" || width === "sm" || index === 0 ? 16 : 0,
              borderBottomLeftRadius: width === "xs" || width === "sm" || index === 0 ? 16 : 0
            }}
            color={selected === index ?
              backgroundColor
              :
              "default"
            }
            onClick={() => onSelect(index)}
            className={classes.button}
          >
            {option}
          </Button>
        })}
      </div>
    );
  }
}

ButtonSwitch.defaultProps = {
  buttonWidth: '194px',
  backgroundColor: 'secondary'
}

ButtonSwitch.propTypes = {
  backgroundColor: PropTypes.string,
  options: PropTypes.array.isRequired,
  selected: PropTypes.number.isRequired,
  onSelect: PropTypes.func.isRequired,
  buttonWidth: PropTypes.string
}

export default withWidth()(withStyles(styles)(ButtonSwitch))