import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { globalStyles } from '../style/gotheme';

const styles = theme => {
  return Object.assign({
    linkButton: {
      fontSize: '16px',
      fontFamily: 'roboto',
      color: 'white',
      borderRadius: 8,
      fontWeight: 400,
      height: 48,
      letterSpacing: '0.06em',
      width: 256,
      border: 'solid 1px rgba(25, 108, 220, 1)',
      '&:hover': {
        background: 'rgba(27, 87, 178, 1)',
        boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.16)'
      }
    }
  }, globalStyles)
};

class LinkButton extends React.Component {

  render() {

    const { classes, endIcon } = this.props;

    return (
      <Button
        variant="text"
        data-test="link-button"
        onClick={this.props.onClick}
        endIcon={endIcon}
        className={classes.linkButton}>
        {this.props.children}
      </Button>
    );
  }
}

LinkButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  endIcon: PropTypes.object
}

export default withStyles(styles)(LinkButton)