import withWidth from '@material-ui/core/withWidth';
import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import { globalStyles } from '../style/gotheme';
import PageRow from './PageRow';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';

const styles = theme => {
  return Object.assign({
  }, globalStyles)
};

class NotFound extends Component {

  render() {

    const { width } = this.props;
    const isLarge = width === "md" || width === "lg" || width === "xl";

    return (
      <div style={{ backgroundColor: '#F2F7FF' }}>
        {isLarge &&
          <div style={{ position: 'absolute', overflow: 'hidden', width: '100%', right: 0 }}>
            <img
              src="/images/GraphologiIcon.svg"
              style={{
                float: 'right',
                height: 764,
                marginTop: '-100px',
                marginRight: -260
              }}
              alt="" />
          </div>
        }
        <PageRow spaceTop={isLarge ? "100px" : "36px"}>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h1" style={{ marginBottom: isLarge ? "100px" : "20px" }}>Oops!<br/>This Page Does Not Exist</Typography>
            </Grid>
          </Grid>
        </PageRow>
      </div>
    );
  }
}

export default withWidth()(withStyles(styles)(NotFound));