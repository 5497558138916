import withWidth from '@material-ui/core/withWidth';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { globalStyles as styles } from '../style/gotheme';

class PageRow extends Component {

  render() {

    const { color, background, spaceTop, zIndex, spaceBottom, width, showGradient } = this.props;
    const isLarge = width !== "xs" && width !== "sm" && width !== "md";

    return (
      <div data-test="page-row"
        style={{
          width: '100%',
          ...(!showGradient) && { backgroundColor: color },
          ...(background) && { background },
          position: 'relative',
          zIndex,
          ...(!isLarge && showGradient) && {
            background: 'radial-gradient(at center, #1C4B8C, #142B4B)'
          }
        }}>
        <div style={{
          maxWidth: 1272,
          margin: '0px auto',
          paddingTop: spaceTop,
          paddingBottom: spaceBottom,
          paddingLeft: width === "xs" ? 20 : 80,
          paddingRight: width === "xs" ? 20 : 80
        }}>
          {this.props.children}
        </div>
      </div>
    );
  }
}

PageRow.defaultProps = {
  color: 'transparent',
  spaceTop: '0px',
  showGradient: false,
  spaceBottom: '88px'
}

PageRow.propTypes = {
  color: PropTypes.string,
  spaceTop: PropTypes.string,
  spaceBottom: PropTypes.string,
  showGradient: PropTypes.bool,
  zIndex: PropTypes.string
}

export default withWidth()(withStyles(styles)(PageRow));